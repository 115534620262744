import {useRouteError} from "react-router-dom";

const ErrorPage = () => {
  return (
    <>
      <h1>Error Page</h1>
    </>
  );
};

export default ErrorPage;