import {createColumnHelper} from "@tanstack/react-table";
import ShippingMethodTableActions from "../../views/settings/shipping-method/ShippingMethodTableActions";
import Card from "../../components/UI/Card";
import Table from "../../components/UI/Table";
import {getUsers} from "../../api/user";
import {useState} from "react";
import UsersForm from "../../views/users/UsersForm";
import CardCreateNewButton from "../../components/UI/CardCreateNewButton";
import UserFormTableAction from "../../views/users/UserFormTableAction";

const UsersList = () => {
  const [openModal, setOpenModal] = useState(false)
  const [user, setEditUser] = useState(null)
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: 'Email'
    }),
    columnHelper.accessor('last_login', {
      id: 'last_login',
      header: 'Last Login'
    }),

    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <UserFormTableAction editRecord={(data)=>handleEditUser(data)}  row={props.row.original} />
    }),
  ];

  const handleEditUser = (data) => {
    setEditUser(data)

    setOpenModal(true)
  }

return (
  <>
      <Card title={"Users"} CardActions={<CardCreateNewButton clickHandler={()=>setOpenModal(true)} />}>
        <Table
          queryKey={"users"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getUsers}
        />
      </Card>

    {openModal &&
      <UsersForm editRecord={user}
                 openModal={openModal}
                 setEditUser={setEditUser}
                 setOpenModal={setOpenModal} />
    }

  </>
)
}

export default UsersList;