import Card from "../../components/UI/Card";
import Loading from "../../components/UI/Loading";
import {useLocation, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import {getCustomerDetails} from "../../api/customers";
import CustomerForm from "../../views/customers/CustomerForm";

const CustomerAddEdit = ()=> {
    const params = useParams()
    const location = useLocation()

    useEffect(() => {
        reset();
    }, [location]);


    const {mutate, isPending} = useMutation({
        mutationFn: () => getCustomerDetails(params.id),
        onSuccess: (data) => {
            Object.keys(data).forEach(key => {
                setValue(key, data[key]);
            });
        }
    })

    useEffect(() => {
        if (params.id) {
            mutate();
        }
    }, [params.id]);

    const {setValue, reset, register, setError,   watch, handleSubmit, formState: { errors }} = useForm()


    return (
        <>
            <div className="row">
                <div className="col_12">
                    <Card title={`${params.id ? 'Edit Customer' : 'Add New Customer'}`}>
                        {isPending ?
                            <Loading /> :
                            <CustomerForm watch={watch}
                                                 setError={setError}
                                                 handleSubmit={handleSubmit}
                                                 errors={errors}
                                                 register={register} />
                        }
                    </Card>
                </div>
            </div>
        </>
    )
}

export default CustomerAddEdit;