import Modal from "../../components/UI/Modal";
import {useForm} from "react-hook-form";
import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import {setValidationMessage} from "../../util/validationMessage";
import {toast} from "react-toastify";
import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {updateLogo} from "../../api/profile";

const LogoUploadComponent = ({openModal, setOpenModal, setUpdateData}) => {
  const [toastId, setToastId] = useState(null);
  const {isPending, mutate} = useMutation({
    mutationFn:  (data) => updateLogo(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      setUpdateData(res)
      toast.update(toastId, { render: `Logo Updated`, type: "success", isLoading: false, autoClose: true });
      setOpenModal(false)
      form.reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, form.setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const form = useForm();

  const onSubmit = (data) => {
    const file = data.logo[0];
    let formData = new FormData()

    if(file)
      formData.append('logo', file)

    mutate(formData)
  }
  return (
    <>
      <Modal title={`Logo Update`} open={openModal} setOpen={setOpenModal} size={"modal_500p"}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12">
              <Input label="Logo"
                     name="logo"
                     errors={form.errors}
                     type={"file"}
                     validationRules={  {required: true } }
                     register={form.register}/>
            </div>
            <div className="col_12">
              <div className="d_flex_end">
                <Button
                  text="save"
                  isPending={isPending}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default LogoUploadComponent