import {useState} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import Card from "../../../components/UI/Card";
import Table from "../../../components/UI/Table";
import {getShippingMethods} from "../../../api/shippingMethods";
import ShippingMethodTableActions from "../../../views/settings/shipping-method/ShippingMethodTableActions";
import ShippingMethodCardActions from "../../../views/settings/shipping-method/ShippingMethodCardActions";
import ShippingMethodFormComponent from "../../../views/settings/shipping-method/ShippingMethodFormComponent";

const ShippingMethodPageComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  const [shippingMethod, setShippingMethod] = useState(null)

  const editHandler = (row) =>{
    setShippingMethod(row)
    setOpenModal(true)
  }

  const addNewShipping = () =>{
    setShippingMethod(null);
    setOpenModal(true);
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('courier.name', {
      id: 'courier.name',
      header: 'Courier'
    }),

    columnHelper.accessor('fee', {
      id: 'fee',
      header: 'Fee',
      cell: props => <span> ${props.row.original.fee.toFixed(2)} </span>
    }),
    // columnHelper.display( {
    //   id: 'actions',
    //   header: "Actions",
    //   classes:'text_right',
    //   cell: props => <ShippingMethodTableActions editRecord={editHandler} row={props.row.original} />
    // }),
  ];

  return (
    <>
      <Card title={"Shipping Methods"}>
        <Table
          queryKey={"shipping-methods"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getShippingMethods}
        />
      </Card>

      <ShippingMethodFormComponent editRecord={shippingMethod}
                            openModal={openModal}
                            setOpenModal={setOpenModal} />
    </>
  )
}

export default ShippingMethodPageComponent