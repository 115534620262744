import Button from "../../../components/UI/Button";
import React from "react";

const AddCodeCardButton = ({setModalStatus})=>{
    return (
        <Button
            text="Add new code"
            variant={"btn_info"}
            onClickHandler={()=>setModalStatus(true)}
        />
    )
}

export default AddCodeCardButton