import API from "../util/api";

export const getUsers = async (params = {}) => {
  let data = null;

  await API.get('/users', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
};



export const storeUser = async (payload) => {
  let data = null;

  await API.post('/users', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateUser = async (id, payload) => {
  let data = null;

  await API.patch(`/users/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getUserDetails = async (id) => {
  let data = null;
  await API.get('/users/'+id)
    .then(res => {

      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteUser = async (payload) => {
  await API.delete('/users/'+payload.id)
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}

