import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const Button = ({classes= '', variant = 'btn_info', disabled = false, text = '', isPending = false, size='btn_md', type='button', onClickHandler = () => {}}) => {

  return (
    <>

      <button type={type}
              disabled={disabled}
              onClick={onClickHandler}
              className={`btn ${classes} ${variant} ${size}`}>
        <span className={`${isPending && 'blur_2px'}  `}>{text}</span>
        {isPending && <span className={'font_12p p_absolute ml_5'}> <FontAwesomeIcon icon={faSpinner} spin/> </span>}  </button>
    </>
  );
};

export default Button;