import API from "../util/api";

export const dashboardData = async () => {
  let data = null;

  await API.get('/dashboard')
    .then(res => {

      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}