import {Link} from "react-router-dom";
import Button from "../../components/UI/Button";

const CustomerTableAction = ({id}) =>{
    return (
        <>
            <Link  to={"/customers/details/"+id} className={"mr_5"}>
                <Button text={'Details'} />
            </Link>
        </>
    );
}

export default CustomerTableAction;