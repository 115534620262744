import Button from "./Button";

const CardCreateNewButton = ({clickHandler, text= 'Add New'}) => {
  return(
    <Button
      text={text}
      variant={"btn_info"}
      onClickHandler={clickHandler}
    />
  );
}

export default CardCreateNewButton