import Card from "../../../../components/UI/Card";
import React from "react";
import Input from "../../../../components/UI/form/Input";
import Button from "../../../../components/UI/Button";
import {useMutation} from "@tanstack/react-query";
import {getCouponCodeDetails} from "../../../../api/coupon";

const CouponCode = ({errors,register, getValues, setValue, applycoupon})=>{


    const {isPending, mutate} = useMutation({
        mutationFn: () => getCouponCodeDetails({coupon_code: getValues('coupon_code'), customer_id: getValues('customer_id')
        }),
        onSuccess: (res) => {
            applycoupon(res)
        },
    });


    const handleCoupon = ()=>{
        if (getValues('coupon_code')) {
            mutate()
        }
    }

    return (
        <Card>
            <h1 className="common_sub_title   mt_0 mb_5">
                <span> Coupon Code</span>

            </h1>

                <div className="row">
                    <div className="col_12">
                        <div className="d_flex_btwn">
                        <Input label=""
                               inline={false}
                               name="coupon_code"
                               classes={"width_full mr_10 mb_5  "}
                               errors={errors}
                               validationRules={{}}
                               register={register}/>
                            <Button
                                text="Apply Coupon"
                                variant={"btn_primary"}
                                classes={"height_40p"}
                                isPending={isPending}
                                onClickHandler={handleCoupon}
                            />
                        </div>
                    </div>
                </div>
        </Card>
    );
}

export default CouponCode