import {Link} from "react-router-dom";
import Button from "../../../../components/UI/Button";
const SubmitActionButtonGroup = ({isloading})=> {
  return (
    <>
      <div className="row mt_10">
        <div className="col_12">
          <div className="d_flex_btwn">
            <Link to="/products">
              <Button
                text="Back to List"
                variant={"btn_primary"}
                type={"submit"}
              />
            </Link>
            <Button
              text="Save"
              isPending={isloading}
              type={"submit"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmitActionButtonGroup