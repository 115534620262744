import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";


const Input = ({ inline = false,
                 label = "",
                 name,
                 placeholder = '',
                 register,
                 validationRules = {},
                 errors =null,
                 type = 'text',
                 classes = ''}) => {

  let errorName = name.replace(/\[/g, ".")
  errorName = errorName.replace(/\]/g, "")

  return (
    <div className={`${ classes } ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
      <label className={validationRules.required ? 'required' : ''}>{label}</label>
      <div className="form_input">
        <input {...register(name, validationRules)}
               type={type}
               placeholder={placeholder}
               className="form_global" />
      </div>
      {getErrorObj(errors, errorName) && <small>{getValidationMessage(getErrorObj(errors, errorName), label)}</small>}
    </div>
  );
};

export default Input;