import {createColumnHelper} from "@tanstack/react-table";
import CustomerActiveComponent from "../../views/customers/CustomerActiveComponent";
import CustomerTableAction from "../../views/customers/CustomerTableAction";
import Card from "../../components/UI/Card";
import Table from "../../components/UI/Table";
import {getCustomers} from "../../api/customers";
import CustomerTableMaillingList from "../../views/customers/CustomerTableMaillingList";

const CustomerList = ()=> {
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: 'Name'
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: 'Email'
        }),
        columnHelper.accessor('last_order', {
            id: 'last_order',
            header: 'Last Order Date'
        }),
        columnHelper.display( {
            id: 'count',
            header: "Order",
            cell: props => <span className={"fw_bold  text_blue p_5 text"}>{props.row.original.count}</span>
        }),

        columnHelper.display( {
            id: 'actions',
            header: "Actions",
            classes:'text_right',
            cell: props => <CustomerTableAction id={props.row.original.id} />
        }),
    ];
    return (
        <>
            <Card title={"Customers"}>
                <Table
                    queryKey={"customers"}
                    columns={columns}
                    queryParams={{per_page: 10}}
                    queryFn={getCustomers}
                ></Table>
            </Card>
        </>
    )
}

export default CustomerList