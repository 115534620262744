import API from "../util/api";
export const getTransactions = async (params) => {
  let data = null;

  await API.get('/commission-transactions', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}