import API from "../util/api";

export const getProfileInfo = async () => {
  let data = null;

  await API.get('/vendor-information')
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateLogo = async (payload) => {
  let data = null;

  await API.post('/upload-logo', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const updateVendorInformation = async (payload) => {
  let data = null;

  await API.post('/update-information', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}