import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import Modal from "../../components/UI/Modal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useState} from "react";
import {storeCourier, updateCourier} from "../../api/couriers";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {useForm} from "react-hook-form";
import {storeUser, updateUser} from "../../api/user";

const UsersForm = ({editRecord = null, openModal, setOpenModal, setEditUser}) => {

  const {setValue, reset, register, setError, handleSubmit, formState: { errors }} = useForm({
    defaultValues:{
      email: editRecord ? editRecord.email : '',
      password:  '',
      name: editRecord ? editRecord.name : '',
    }
  });

  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: editRecord ? (data) => updateUser(editRecord.id, data) : storeUser,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `User ${editRecord ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'users',
      });
      setOpenModal(false)
      setEditUser(null)
      reset();

    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }

  return(
    <>
      <Modal title={`${editRecord ? 'Edit' : 'Add'} User`} open={openModal} setOpen={setOpenModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_6 pr_20">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Input label="Email"
                     name="email"
                     type={'email'}
                     errors={errors}
                     validationRules={{required: true, type: 'email'}}
                     register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Input label="Password"
                     name="password"
                     type={'password'}
                     errors={errors}
                     validationRules={!editRecord && {required: true}}
                     register={register}/>
            </div>
            <div className="col_6 pr_20">
              <Input label="Confirm Password"
                     name="confirm_password"
                     type={'password'}
                     errors={errors}
                     validationRules={!editRecord && {required: true}}
                     register={register}/>
            </div>

          </div>

          <div className="col_12 mb_10">
            <div className="d_flex_end">
              <Button
                text="save"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default UsersForm