import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const UploadedMediaItemComponent = ({isPending = false,
                                      currentDeleteItem = '',
                                      image,
                                      deleteProduct,
                                      indexKey}) => {
  const errorHandle = (e) => {
    e.target.src = "/images/no-image.png"
  }

  return (
    <div className="image_item p_relative">
      <img src={image.url} onError={errorHandle} alt=""/>
      {isPending && currentDeleteItem === indexKey ?
        <span className={"t_0 r_0 cursor_pointer p_absolute font_12p"}><FontAwesomeIcon icon={faSpinner} spin/></span>  :
        <span className="close_icon close_icon_md t_0 r_0 cursor_pointer " onClick={() => {
          deleteProduct(image, indexKey)
        }}/>
      }
    </div>
  )
}

export default UploadedMediaItemComponent;