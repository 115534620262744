import Card from "../../components/UI/Card";
import React, {useEffect} from "react";
import ProductFilterComponent from "../../views/products/product/List/includes/ProductFilterComponent";
import TabComponent from "../../components/UI/TabComponent";
import ItemListComponent from "../../views/products/product/List/ItemListComponent";
import ProductModificationActionsComponent
  from "../../views/products/product/List/includes/ProductModificationActionsComponent";
import {useDispatch, useSelector} from "react-redux";
import {
  setAllProducts,
  setTabStatus,
  setProductPagination, setSendRequest, setRequestStatus, setSelectToggle
} from "../../store/slices/productSlice";
import {useMutation} from "@tanstack/react-query";
import {getProducts} from "../../api/products";
import PreloaderComponent from "../../components/UI/PreloaderComponent";
const ProductList = ()=>{
  const dispatch = useDispatch();
  const store = useSelector(state => state.product);

  const tabChangeEvent = (activeTab)=>{
    if(activeTab) {
      dispatch(setTabStatus(activeTab))
      dispatch(setSelectToggle({type:'unselect'}));
      dispatch(setSendRequest(true))
    }
  }

  const tabItems = [
    {
      id: "active_item",
      title: "Active Item",
      component: <ItemListComponent />
    },
    {
      id: "inactive_item",
      title: "Inactive Item",
      component: <ItemListComponent />
    }
  ];

  const {mutate:getProductMutate, isPending} = useMutation({
    mutationFn: (variables) => getProducts({...store.initialParams, status: store.activeTab}),
    onSuccess: (response, payload) => {
          dispatch(setAllProducts(response.data))
          dispatch(setProductPagination(response.meta))
    }
  })


  useEffect(()=>{
    if(store.sendRequest) {
      getProductMutate()
    }
  },[store.sendRequest])

  useEffect(()=>{
    getProductMutate()
  },[])

  useEffect(()=>{
    if(!isPending)
      dispatch(setSendRequest(false))

      dispatch(setRequestStatus(isPending))
  },[isPending])


  return (
      <Card title={"Products"}>
        {store.requestStatus && <PreloaderComponent /> }

        <ProductFilterComponent />

        <ProductModificationActionsComponent/>


        <div className={"p_relative"}>
          <TabComponent items={tabItems} tabChangeEvent={tabChangeEvent} />
        </div>
      </Card>
  );
}

export default ProductList;