import axios from 'axios';
const user = JSON.parse(localStorage.getItem('user')) || null;

if (user) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  axios.defaults.headers.common['Accept'] = "application/json";
}

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})