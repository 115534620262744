import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";
import React from "react";

const  CouponListCardAction = ()=>{
    return (
        <div>

        <Link  to={"/coupons/create"} className="mr_5">
            <Button text={'Add New Coupon'} variant={'btn_info'} />
        </Link>
            <Link to={`/stylepick-coupons`}>
                <Button
                    text="Stylepick Coupons"
                    variant={"btn_info"}
                />
            </Link>
            </div>
    );
}
export default CouponListCardAction