import {Outlet, useLoaderData, useLocation, useNavigate, useNavigation} from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import LeftNavComponent from "./LeftNavComponent";
import PreloaderComponent from "../../components/UI/PreloaderComponent";
import {getUser} from "../../api/auth";
import {useEffect} from "react";

export async function loader() {
  const notRedirectPath = ['/reset-password', '/new-password']
  let user = null;
  try {
    if(!notRedirectPath.includes(window.location.pathname))
      user = await getUser()

    if(!user && (notRedirectPath.includes(window.location.pathname)))
      return user;

    if(!user && window.location.pathname !== '/login'){
      window.location.replace(`/login`);
    } else {
      return user
    }
  } catch (error) {
    if(window.location.pathname !== '/login'){
      window.location.replace(`/login`);
    }
  }
  return user;
}

const RootLayout = () => {
  const location = useLocation();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const outletPath = ['/login', '/reset-password', '/new-password']
  const notRedirectPath = ['/reset-password', '/new-password']

  const user = useLoaderData();

  useEffect(() => {
    if (!user && notRedirectPath.includes(location.pathname)) {
      return
    }
    if (!user && location.pathname !== '/login') {
      navigate('/login');
    }
  }, []);


  if (location.pathname === '/login') {
    document.body.classList.add(
      'login_page'
    );
  } else {
    document.body.classList.remove('login_page');
  }

  return (
    <>
      {navigation.state === "loading" && <PreloaderComponent />}
      <div id="wrap" className={!outletPath.includes(location.pathname) ? 'has_left_menu' : ""}>
        {outletPath.includes(location.pathname) ?
          <Outlet /> :
          <>
            <HeaderComponent />
            <LeftNavComponent />
            <div id="content_wrap">
              <div className="container">
                <Outlet />
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
};

export default RootLayout;