import Card from "../../../../components/UI/Card";
import Button from "../../../../components/UI/Button";
import ProductList from "./ProductList";
import {useState} from "react";
import OrderProductList from "./OrderProductList";

const OrderProductsComponent = ({setValue, control, errors, getValues, register, watch}) => {
  const [listOpen, setListOpen] = useState(false)
  return (
    <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
        <span> Products</span>
        <span className="fw_regular font_15p d_flex_center">
          <Button text={"Add Product"} onClickHandler={()=>setListOpen(true)} />
        </span>
      </h1>
      <ProductList status={listOpen} setStatus={setListOpen} setValue={setValue} register={register} errors={errors} getValues={getValues} control={control} />
      <OrderProductList setValue={setValue} register={register} errors={errors} getValues={getValues} control={control} watch={watch} />
    </Card>
  );
}

export default OrderProductsComponent