import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import Card from "../../../../components/UI/Card";
import {getCategories, sortCategory, storeCategory, updateCategory} from "../../../../api/categories";
import CategoryColumnComponent from "../../../../views/products/settings/categories/CategoryColumnComponent";
import CategoryForm from "../../../../views/products/settings/categories/CategoryForm";
import CategoryListActions from "../../../../views/products/settings/categories/CategoryListActions";
import {ReactSortable} from "react-sortablejs";
import CategoryAccordianItem from "../../../../views/products/settings/categories/includes/CategoryAccordianItem";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";

const CategoryList = () => {

  const [openModal, setOpenModal] = useState(false)
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [ids, setIds] = useState([]);

  const { data } = useQuery({
    queryKey: ['categories'],
    queryFn:()=> getCategories,
    initialData: () => []
  });

  useEffect(() => {
    if (data !== undefined) {
      setCategories(data);
    }
  }, [data])


  const editHandler = (category) => {
    setCategory(category);
    setOpenModal(true);
  }

  const addNewCategory = () =>{
    setCategory(null);
    setOpenModal(true);
  }

  const [toastId, setToastId] = useState(null);
  const { mutate, isIdle,  reset} = useMutation({
    mutationFn: async  (ids) => sortCategory( {ids:[...ids]}),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, {
        render: `Category Sorted!`,
        type: "success",
        isLoading: false,
        autoClose: true
      });
      setTimeout(()=>{
        reset()
      }, 1000)
      //
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      toast.update(toastId, {
        render: errorMsg,
        type: "error",
        isLoading: false,
        autoClose: true
      });
    }
  })

  const categorySort = (list, type = '')=>{
    if(type === 'child'){
      let ids = list.map(obj => obj.id);
      mutate(ids)
    } else {
      const checkEquality = JSON.stringify(list) === JSON.stringify(categories);
      if (!checkEquality) {
        let ids = list.map(obj => obj.id);
        mutate(ids)
      }
      setCategories(list)
    }
  }

  return (
    <>
      <Card title={"Categories"}   >
        <div className="accordion_wrap categories_row">
          {/*<ReactSortable animation={200}*/}
          {/*               list={categories}*/}
          {/*               setList={categorySort} >*/}
            {categories.length && categories.map(category => (
              <CategoryAccordianItem key={category.id} category={category} editHandler={editHandler} categorySort={(list)=>categorySort(list, 'child')} />
            ))}
          {/*</ReactSortable>*/}
        </div>
      </Card>

      <CategoryForm category={category}
                    openModal={openModal}
                    setOpenModal={setOpenModal}  />
    </>
  )
}

export default CategoryList;