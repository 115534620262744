import Card from "../../../../components/UI/Card";
import ShippingMethodsList from "./ShippingMethodsList";
import {useEffect, useState} from "react";
import Button from "../../../../components/UI/Button";

const SelectShippingMethods = ({errors,setValue}) => {
  const [shippingMethod, setShippingMethod] = useState(null)
  const [openModal, setModal] = useState(false)

  const selectNewShipping = (row) => {
    setValue('shipping_cost', row.fee)
    setValue('shipping_method_id', row.id ? row.id : '')
    setShippingMethod(row)
  }
  const newErrors = Object.keys(errors).map((key) =>  key);

  return (
    <>
      <Card>
        <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
          Shipping Methods
          <Button text={"Change Shipping Method"} onClickHandler={()=>setModal(!openModal)} />
        </h1>
        {newErrors.includes('shipping_method_id') && <span className={'text_red'}>Please select Shipping method</span>}
        {shippingMethod &&  <p className="mb_5">
          <b>Name:</b> {shippingMethod.name} <br/>
          <b>Courier:</b> {shippingMethod.courier ? shippingMethod.courier.name : ''}<br/>
          <b>Cost:</b> ${shippingMethod.fee ? shippingMethod.fee.toFixed(2) : 'Actual Rate'}
        </p>}
      </Card>
      <ShippingMethodsList openModal={openModal} setModal={setModal}  setShippingMethod={selectNewShipping} />
    </>
  );
}

export default SelectShippingMethods