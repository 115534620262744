import Input from "../../components/UI/form/Input";
import ReactiveButton from "reactive-button";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {useNavigate, useParams} from "react-router-dom";
import {storeCustomer, updateCustomer} from "../../api/customers";
import Button from "../../components/UI/Button";

const CustomerForm = ({watch, setError, handleSubmit, errors, register}) => {

    const [toastId, setToastId] = useState(null);
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const params = useParams()

    const {isPending, mutate} = useMutation({
        mutationFn: params.id ? (data) => updateCustomer(params.id, data) : storeCustomer,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, { render: `Customer ${params.id ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'customers',
            });

            navigate('/customers');
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }

            toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
        }
    });

    const onSubmit = (data) => {
        mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
                <div className="col_6 pr_20">
                    <Input label="First Name"
                           name="first_name"
                           errors={errors}
                           validationRules={{required: true, type: 'string'}}
                           register={register}/>
                </div>

                <div className="col_6 pr_20">
                    <Input label="Last Name"
                           name="last_name"
                           errors={errors}
                           validationRules={{required: true, type: 'string'}}
                           register={register}/>
                </div>

                <div className="col_6 pr_20">
                    <Input label="Email"
                           name="email"
                           type={'email'}
                           errors={errors}
                           validationRules={{required: true, type: 'email'}}
                           register={register}/>
                </div>

                <div className="col_6 pr_20">
                    <Input label="Password"
                           name="password"
                           type={'password'}
                           errors={errors}
                           validationRules={{required: params.id === undefined, type: 'string'}}
                           register={register}/>
                </div>

            </div>



            <div className="col_12">
                <div className="d_flex_end">
                    <Button
                        isPending={isPending}
                        text="SAVE"
                        type="submit"
                    />
                </div>
            </div>

        </form>
    )
}

export default CustomerForm;