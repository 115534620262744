import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Button from "../../../../components/UI/Button";
import Card from "../../../../components/UI/Card";
import {setOrderForm} from "../../../../store/slices/orderSlice";
import {useParams} from "react-router-dom";

const CustomerInfoComponent = ({setValue, register, errors}) =>{
  const {orderForm} = useSelector(state => state.orders)
  const [customer, setCustomer] = useState(null)
  const dispatch = useDispatch()
  const params = useParams()
  useEffect(()=>{
    if(customer === null) {
      setCustomer(orderForm.customer)
    }
  }, [orderForm])

  useEffect(()=>{
      if(customer) {
        setValue('customer_id', customer.id)
      }
  }, [customer])


  const searchAgain = () => {
    dispatch(setOrderForm({customer: null, products: []}))
  }

  return(
    <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
        <span> Customer Information</span>
        {(!params.id) &&
        <span className="fw_regular font_15p d_flex_center">
          <Button onClickHandler={searchAgain} text={"Change Customer"}/>
        </span>
        }
      </h1>
      {customer &&
      <p className="mb_5">
        <b>Name:</b> {customer.name} <br/>
        <b>Email:</b> {customer.email} <br/>
        <b>Phone:</b> {customer.phone} <br/>
      </p>
      }
    </Card>
  );
}

export default CustomerInfoComponent