import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {newPasswordSubmit} from "../../api/auth";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";

const NewPassword = () => {
  const [queryParams, setQueryParams] = useState({});
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setQueryParams(params);
  }, []);

  const {register, handleSubmit, setError} = useForm();
  const [toastId, setToastId] = useState(null);

  const {isPending, error, mutate} = useMutation({
    mutationFn: newPasswordSubmit,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      window.location.replace("/login");
      toast.update(toastId, { render: `Password Has Been Changed`, type: "success", isLoading: false, autoClose: true });
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }
      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const submitHandler = (data) => {
    data.token = queryParams ? queryParams.token : ''
    mutate(data);
  };

  return(
    <>
      <div className="login_wrap">
        <div className="login_wrap_inner">
          <div className="login_logo">
            <a href="#"><img src="https://lynkto.net/assets/images/logo-white.png" alt=""/></a>
          </div>

            <form onSubmit={handleSubmit(submitHandler)}>
              <input type="password"
                     id="username"
                     autoComplete="password"
                     className={"my-style"}
                     {...register('password')}
                     placeholder="New password"/>
              <label htmlFor="username">New Password</label>

              {(error && error.message.errors['password']) && <div className="has_error username">{error.message.errors['password'][0]}</div>}
              {(error && error.message.errors['token']) && <div className="has_error username">{error.message.errors['token'][0]}</div>}

              <input type="password"
                     id="password"
                     autoComplete="confirm_password"
                     className={"my-style"}
                     {...register('confirm_password')}
                     placeholder="Confirm New Password"/>
              <label htmlFor="password">Confirm Password</label>

              {(error && error.message.errors['confirm_password']) && <div className="has_error password">{error.message.errors['confirm_password'][0]}</div>}

              <button type="submit" disabled={isPending}>Reset Password</button>
            </form>
        </div>
      </div>
    </>
  )
}

export default NewPassword