import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import { sendResetPasswordLink} from "../../api/auth";
import {useState} from "react";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import * as PropTypes from "prop-types";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



FontAwesomeIcon.propTypes = {
  icon: PropTypes.any,
  spin: PropTypes.bool
};
const ResetPassword = () => {
  const {register, handleSubmit, setError} = useForm();
  const [toastId, setToastId] = useState(null);
  const [linkSend, setLinkSend] = useState(false)
  const {isPending, error, mutate} = useMutation({
    mutationFn: sendResetPasswordLink,
    onMutate: () => {
      setLinkSend(false)
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      setLinkSend(true)
      toast.update(toastId, { render: `Password Reset Link Has Been Send`, type: "success", isLoading: false, autoClose: true });
    },
    onError: (error) => {
      setLinkSend(false)
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }
      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const submitHandler = (data) => {
    setLinkSend(false)
    mutate(data);
  };
  return (
    <>
      <div className="login_wrap">
        <div className="login_wrap_inner reset_pass_wrap">
          <div className="login_logo">
            <a href="#"><img src="https://lynkto.net/assets/images/logo-white.png" alt=""/></a>
          </div>
          {!linkSend ?
            <form onSubmit={handleSubmit(submitHandler)}>
              <input type="text"
                     id="username"
                     autoComplete="email"
                     className={"my-style"}
                     {...register('email')}
                     placeholder="Enter your email"/>
              <label htmlFor="username">Email</label>

              {(error && error.message.errors['email']) &&
              <div className="has_error username">{error.message.errors['email'][0]}</div>}

              <button type="submit" disabled={isPending}>Send Link {isPending && <FontAwesomeIcon icon={faSpinner} spin/>} </button>
            </form>
            :
            <div className={"reset_page_login_link"}>
              <Link to={"/login"}> Login </Link>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default ResetPassword