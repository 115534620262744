import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getCategories} from "../../../../../api/categories";
import Button from "../../../../../components/UI/Button";
import {useDispatch, useSelector} from "react-redux";
import {setParams as setStoreParams, setSendRequest} from "../../../../../store/slices/productSlice";

const ProductFilterComponent = () => {
  const staticSearchParams = {
    page: 1,
    search: '',
    search_columns: {
      name: 0,
      description: 0,
      style_no: 0
    },
    sort: 'created_at',
    sort_order: 'desc',
    status: 1,
    vendor_id: '',
    parent_category_id: '',
    second_category_id: '',
    third_category_id: '',
    per_page: 50
  }
  const store = useSelector(state => state.product)
  const [params, setParams] = useState({...store.initialParams});
  const dispatch = useDispatch();

  const [subCategories, setSubCategories] = useState([])
  const [subSubCategories, setSubSubCategories] = useState([])

  const {data: categories} = useQuery({
    queryKey:['categories'],
    queryFn: () => getCategories(),
    initialData: () => []
  })

  useEffect(()=>{
    if(categories && categories.length) {
      let subcategories = categories.find(cat => +cat.id === + params.parent_category_id)?.sub_categories
      setSubCategories(subcategories)
    }
  }, [params.parent_category_id])

  useEffect(()=>{
    if(subCategories) {
      let subcategories = subCategories.find(cat => +cat.id === + params.second_category_id)?.sub_categories
      setSubSubCategories(subcategories)
    }
  }, [params.second_category_id])

  const HandleCheckboxChange = (event,column) => {
    setParams((prevParams) => ({
      ...prevParams,
      search_columns: {
        ...prevParams.search_columns,
        [column]: event.target.checked
      }
    }));
  }
  const handleInputChange = (event, column, index = 0) => {
      setParams((prevParams) => ({
        ...prevParams,
        [column]: event.target.value,
        page:1,
      }));

    if(column === 'parent_category_id' || column === 'second_category_id' || column === 'third_category_id' || column === 'vendor_id' || column === 'sort' || column === 'per_page'){
      dispatch(setSendRequest(true))
    }
  }

  const enterPressHandler = (e) => {
    if(e.key === 'Enter'){
      dispatch(setSendRequest(true))
    }
  }

  useEffect(()=> {
    setParams(store.initialParams)
  }, [store.initialParams])

  useEffect(()=> {
    dispatch(setStoreParams(params))
  }, [params])



  const sendSearchRequest = ()=>{
    dispatch(setSendRequest(true))
  }
  const resetSearch = ()=>{
    dispatch(setStoreParams({...staticSearchParams, status: store.activeTab}))
    dispatch(setSendRequest(true))
  }

  return (
    <>
      <div className="color_filter_content pt_15">
        <div className="form_row">
          <div className="form_input">
            <div className="search_input">
              <input type="text" className="form_global"
                     value={params.search}
                     onKeyPress={enterPressHandler}
                     placeholder={"Search by name, description and SKU"}
                     onChange={(event)=>handleInputChange(event, 'search')}
              />
            </div>
            <Button text={"Search"}
                    variant={"btn_primary"}
                    classes={'mr_10'}
                    onClickHandler={sendSearchRequest} />
            
            <Button text={"Reset"}
                    variant={"btn_primary"}
                    onClickHandler={resetSearch} />
          </div>
        </div>
        <div className="row">
          <div className="col_3">
            <div className="d_flex_start mb_10">
              <select className="form_global max_width_200p" value={params.sort} onChange={(event)=>{handleInputChange(event,'sort')}}>
                <option value="created_at-asc">Latest First</option>
                <option value="created_at-desc">Oldest First</option>
                <option value="sort-desc">Sort Number</option>
                <option value="updated_at-desc">Last Update</option>
                <option value="price-asc">Price Low to High</option>
                <option value="price-desc">Price High to Low</option>
                <option value="sku-asc">SKU</option>
              </select>
            </div>
          </div>

          <div className="col_3">
            <select className="form_global max_width_200p ml_5" value={params.parent_category_id} onChange={(event)=>{handleInputChange(event,'parent_category_id', 0)}}>
              <option value="">All Category</option>
              {(categories && categories.length) && categories.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>
          </div>
          <div className="col_2">
            <select className="form_global max_width_200p ml_5" value={params.second_category_id } onChange={(event)=>{handleInputChange(event,'second_category_id', 0)}}>
              <option value="">All Category</option>
              {(subCategories && subCategories.length) && subCategories.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>
          </div>

          <div className="col_2">
            <select className="form_global max_width_200p ml_5" value={params.third_category_id} onChange={(event)=>{handleInputChange(event,'third_category_id', 0)}}>
              <option value="">All Category</option>
              {(subSubCategories && subSubCategories.length) && subSubCategories.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>
          </div>

          <div className="col_2">
            <div className="d_flex_end">
              <select className="form_global max_width_200p" value={params.per_page} onChange={(event)=>{handleInputChange(event,'per_page')}}>
                <option value="36">36</option>
                <option value="50">50</option>
                <option value="86">86</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductFilterComponent;