import React, {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {storeCoupon} from "../../api/coupon";
import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import {useFieldArray, useForm} from "react-hook-form";
import RadioBox from "../../components/UI/form/RadioBox";
import Select from "../../components/UI/form/Select";
import Checkbox from "../../components/UI/form/Checkbox";
import Card from "../../components/UI/Card";
import CouponFormCodesComponent from "../../views/coupon/coupon-details/CouponFormCodesComponent";

const CouponForm = ()=>{
    const codeObject = {code: '', active: "1", expires_at: '', max_redemptions: '', minimum_amount: '', first_time_transaction: false, free_shipping: false}
    const {setValue,  control, register, setError, getValues,  watch, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{
            type: "percentage",
            valid: "1",
            duration: "forever",
            max_redemptions: '',
            codes: []
        }
    })
    const { append: appendVariant} = useFieldArray({
        control,
        name: 'codes',
    });

    const [toastId, setToastId] = useState(null);
    const [type, setType] = useState('percentage');
    const [checkedStatus, setCheckedStatus] = useState({
        type: 'percentage',
        limit_redems: false,
        coupon_date_limit: false,
        has_codes: false,
    });


    useEffect(()=>{
        if(getValues('type') === 'percentage')
            setValue('amount_off', '')

        if(getValues('type') === 'fixed')
            setValue('percentage_off', '')

        setType(getValues('type'))

    }, [watch('type')])

    const handleCheckbox = (e, column) =>{
        setCheckedStatus(prevStatus => ({
            ...prevStatus,
            [column]: e.target.checked,
        }));

        if(column === 'has_codes'){
            if(!getValues('codes').length) {
                appendVariant({
                    ...codeObject
                })
            }
        }
    }

    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const {isPending, mutate} = useMutation({
        mutationFn:  storeCoupon,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, { render: `New coupon has been add'}`, type: "success", isLoading: false, autoClose: true });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'coupons',
            });

            navigate('/coupons');
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }

            toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
        }
    });

    useEffect(()=>{
        if(getValues('limit_redems')){
            setValue('max_redemptions', 1)
        } else {
            setValue('max_redemptions', '')
        }
    }, [watch('limit_redems')])

    const onSubmit = (data) => {
        mutate(data)
    }
    return(
        <Card title={"Create a coupon"}>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row">
                    <div className="col_6 pr_20">
                        <RadioBox label="Status"
                                  name={`valid`}
                                  errors={errors}
                                  optionValue={'id'}
                                  optionLabel={'name'}
                                  options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                                  validationRules={{required:true, type: 'string'}}
                                  register={register}/>
                    </div>
                    <div className="col_6 pr_20">
                        <Input label="Name"
                               name="name"
                               errors={errors}
                               validationRules={{required: true, type: 'string'}}
                               register={register}/>
                        <span className={'font_12p mb_20'}>Note: This will appear on customers' receipts and invoices.</span>
                    </div>
                    <div className="col_6 pr_20">
                        <Select
                            name={`duration`}
                            options={[{id:'forever', name: "Forever"}, {id: "once", name:"Once"}]}
                            label={"Duration"}
                            errors={errors}
                            validationRules={{required: true}}
                            register={register} />
                        <span className={'font_12p mb_20'}>Note: For customers, this determines how long this coupon will apply once redeemed. One-off invoices accept both "once" and "forever".</span>

                    </div>
                    <div className="col_6 pr_20">
                        <RadioBox label="Type"
                                  name="type"
                                  errors={errors}
                                  options={[{id: "percentage", name:"Percentage discount"}, {id: "fixed", name: 'Fixed amount discount'}]}
                                  validationRules={{required:true, type: 'string'}}
                                  register={register}/>
                        {type === 'percentage' ?
                            <div className="col_12 pr_20">
                                <Input label="Percentage off"
                                       name="percentage_off"
                                       type={'number'}
                                       errors={errors}
                                       validationRules={{required: true, type:"number"}}
                                       register={register}/>


                            </div> :
                            <div className="col_12 pr_20">
                                <Input label="Discount amount"
                                       name="amount_off"
                                       type={'number'}
                                       errors={errors}
                                       validationRules={{required: true, type:"number"}}
                                       register={register}/>
                            </div>
                        }
                    </div>




                    <div className="col_6 pr_20">
                        <Checkbox name={`coupon_date_limit`}
                                  label={"Limit the date range when customers can redeem this coupon"}
                                  classes="mb_0 pt_15"
                                  register={register}
                                  onChangeHandler={(e)=>handleCheckbox(e, 'coupon_date_limit')}
                                  checked={getValues('coupon_date_limit') ? 1 : 0} />

                        {checkedStatus.coupon_date_limit &&   <div className={"pl_30"}>
                            <Input label="Redeem by date"
                                   name="redeem_by"
                                   classes={"mt_5"}
                                   type={'date'}
                                   errors={errors}
                                   validationRules={{required: true}}
                                   register={register}/>
                        </div>
                        }
                    </div>

                    <div className="col_6 pr_20">
                        <Checkbox name={`limit_redems`}
                                  label={"Limit the total number of times this coupon can be redeemed"}
                                  classes="mb_0 pt_15"
                                  register={register}
                                  onChangeHandler={(e)=>handleCheckbox(e, 'limit_redems')}
                                  checked={getValues('limit_redems') ? 1 : 0} />

                        {checkedStatus.limit_redems &&    <div className={"pl_30"}>
                            <Input label="Redemptions times"
                                   name="max_redemptions"
                                   classes={"mt_5"}
                                   type={'number'}
                                   errors={errors}
                                   validationRules={{required: true, type: "number"}}
                                   register={register}/>
                            <span className={'font_12p mb_20'}>Note: This limit applies across customers so it won't prevent a single customer from redeeming multiple times..</span>
                        </div>
                        }

                    </div>
                    <hr className={'width_full mt_10 mb_10'} />

                    <div className="col_12 pr_20">
                        <span className={"pt_15"}>Codes?</span>
                        <Checkbox name={`has_codes`}
                                  label={"Use customer-facing coupon codes?"}
                                  classes="mb_0"
                                  register={register}
                                  onChangeHandler={(e)=>handleCheckbox(e, 'has_codes')}
                                  checked={getValues('has_codes') ? 1 : 0} />

                    </div>
                </div>

                {checkedStatus.has_codes && <>
                    <CouponFormCodesComponent
                        setValue={setValue}
                        control={control}
                        register={register}
                        setError={setError}
                        getValues={getValues}
                        watch={watch}
                        checkedStatus={checkedStatus}
                        errors={errors}
                        hangleCheckboxChange={handleCheckbox}
                         />

                </>}

                <div className="col_12 mt_15">
                    <div className="d_flex_end">
                        <Button
                            isPending={isPending}
                            text="SAVE"
                            type="submit"
                        />
                    </div>
                </div>

            </form>
        </Card>
    )
}

export default CouponForm