export const InventoryCombinationHeading = ()=>{
  return (
    <>
      <div className="row mb_10">
        <div className="col_3">
          <span className={"fw_bold"}>Combination</span>
        </div>
        <div className="col_3">
          <span className={"fw_bold"}>SKU</span>
        </div>
        <div className="col_3">
          <span className={"fw_bold"}>Price</span>
        </div>
        <div className="col_3">
          <span className={"fw_bold"}>Available Quantity</span>
        </div>
      </div>
      <hr/>
      <br/></>
  )
}

export default InventoryCombinationHeading