import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Loading = () => {
  return (
    <div className="row">
      <div className="col_12 text_center">
        <FontAwesomeIcon icon={faSpinner} spin/>
      </div>
    </div>
  );
};

export default Loading;