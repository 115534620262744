import API from "../util/api";

export const getVendorOrders = async (params) => {
  let data = null;

  await API.get('/orders', {params:{...params}})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getOrderTypes = async () => {
  let data = null;

  await API.get('/order/enumeration')
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getOrders = async (params) => {
  let data = null;

  await API.get('/orders', {params:{...params}})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getOrderDetails = async (id) => {
  let data = null;

  await API.get('/orders/'+id)
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const deleteOrder = async (payload) => {
  await API.delete('/orders/'+payload.id)
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}

export const companyOrderDetails = async (params) => {
  let data = null;
  await API.get(`company-order/${params.id}/${params.vendor}`)
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const creteOrderSubmit = async (payload) => {
  let data = null;
  await API.post(`orders`, payload)
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const updateOrder = async (id, payload) => {
  let data = null;
  await API.patch(`orders/${id}`, payload)
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteOrderProduct = async (id, payload) => {
  console.log(payload)
  let data = null;
  await API.delete(`orders-product`, {params: {id}})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}