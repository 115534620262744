import {createSlice} from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')) || null
  },
  reducers: {
    login: (state, payload) => {
      state.user = payload.payload;
      localStorage.setItem('user', JSON.stringify(payload.payload));
    },
    logout: (state, payload) => {
      state.user = null;
      localStorage.removeItem('user');
    }
  }
});

export const {login, logout} = authSlice.actions;

export default authSlice.reducer;