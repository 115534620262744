import Card from "../../../components/UI/Card";
import React from "react";
import CouponUpdateCardButton from "../CouponUpdateCardButton";

const CouponDetailsCard = ({coupon, setModalStatus})=>{
    return (
        <Card title={"Details"} CardActions={coupon.vendor_id && <CouponUpdateCardButton setModalStatus={setModalStatus} />}>
            <div className="row">
                <div className="col_6">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td className="width_260p">Name:</td>
                            <td className="width_260p">{coupon.name}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Created:</td>
                            <td className="width_260p">{coupon.created_at}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Times of Redeemed:</td>
                            <td className="width_260p">{coupon.times_redeemed}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Valid:</td>
                            <td className="width_260p">{coupon.valid ? 'Yes' : 'No'}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div className="col_6">
                    <table className="table">
                        <tbody>
                        {coupon.percentage_off &&
                        <tr>
                            <td className="width_260p">Percentage discount:</td>
                            <td className="width_260p">{coupon.percentage_off}% off</td>
                        </tr>
                        }

                        {coupon.amount_off &&
                        <tr>
                            <td className="width_260p">Fixed amount discount:</td>
                            <td className="width_260p">${coupon.amount_off}</td>
                        </tr>
                        }
                        <tr>
                            <td className="width_260p">Duration:</td>
                            <td className="width_260p">{coupon.duration}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Maximum redemptions:</td>
                            <td className="width_260p">{coupon.max_redemptions}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Expires on:</td>
                            <td className="width_260p">{coupon.redeem_by}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default CouponDetailsCard