import {useFieldArray, useForm} from "react-hook-form";
import CustomerInfoComponent from "./customer-info/CustomerInfoComponent";
import OrderProductsComponent from "./products/OrderProductsComponent";
import OrderFormSubmitBtn from "./OrderFormSubmitBtn";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {creteOrderSubmit, updateOrder} from "../../../api/orders";
import {useEffect, useState} from "react";
import OrderSummaryComponent from "./order-info/OrderSummaryComponent";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useSelector} from "react-redux";
import BillingAddress from "./order-addresses/BillingAddress";
import ShippingAddress from "./order-addresses/ShippingAddress";
import SelectShippingMethods from "./shipping-methods/SelectShippingMethods";
import OrderNotes from "./notes/OrderNotes";
import OrderVendorCommission from "./OrderVendorCommission";
import CouponCode from "./coupon/CouponCode";

const FormComponent = () =>{
  const params = useParams()
  const [coupon, setCoupon] = useState(null)
  const {setValue, control, reset, watch, register, setError, handleSubmit, getValues, formState: { errors }} = useForm({
    defaultValues:{
      shipping_cost: 0,
      shipping_method_id: '',
      discount: 0,
    }
  });
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // reset();
  }, [location]);

  const { append} = useFieldArray({
    control,
    name: 'products',
  });
  const {products} = useSelector(state => state.orders.orderForm);

  //For Edit Order
  const {orderForm} = useSelector(state => state.orders)
  useEffect(()=>{
    if(orderForm){
      setValue('shipping_address_id', orderForm.shipping_address_id)
      setValue('shipping_name', orderForm.shipping_name)
      setValue('shipping_address', orderForm.shipping_address)
      setValue('shipping_mobile', orderForm.shipping_mobile)
      setValue('shipping_city', orderForm.shipping_city)
      setValue('shipping_postal_code', orderForm.shipping_postal_code)
      setValue('shipping_country', orderForm.shipping_country)
      setValue('billing_address_id', orderForm.billing_address_id)
      setValue('billing_name', orderForm.billing_name)
      setValue('billing_address', orderForm.billing_address)
      setValue('billing_mobile', orderForm.billing_mobile)
      setValue('billing_city', orderForm.billing_city)
      setValue('billing_postal_code', orderForm.billing_postal_code)
      setValue('billing_country', orderForm.billing_country)
      setValue('discount', +orderForm.discount)
      setValue('shipping_cost', +orderForm.shipping_cost)
      setValue('shipping_method_id', +orderForm.shipping_method_id ? orderForm.shipping_method_id : '')
      setValue('vendor_note', orderForm.vendor_note)
      setValue('buyer_note', orderForm.buyer_note)

    }
  }, [params])
  //For Edit order

  const [toastId, setToastId] = useState(null);
  const {mutate, isPending, } = useMutation({
    mutationFn: (data) => params && params.id ? updateOrder(params.id, data): creteOrderSubmit(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      reset();
      navigate("/orders")
      toast.update(toastId, { render: `Order Submited`, type: "success", isLoading: false, autoClose: true });
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);

        if (error.message.message)
          errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    if(products.length){
      products.map((product, i)=>{
        let combination = product.product_variant_values;
        data['products'].push({
          product_name: product.product_name,
          product_variant_values: combination,
          product_id: product.product_id,
          variant: '',
          price: product.price,
          quantity: product.quantity,
          id: product.id ? product.id : null,
        })
      })
    }
    setTimeout(()=>{
      mutate(data)
    },50)

  }

  return (
    <>
      <div className="row">
        <div className="col_12">
          <h1 className="common_title">{orderForm && orderForm.id ? 'Order Number: '+orderForm.order_number : 'Create Order'}</h1>
        </div>
      </div>
      <form className={"mb_20"} onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col_7">
          <CustomerInfoComponent setValue={setValue} register={register} errors={errors}  />
          <BillingAddress setValue={setValue} register={register} errors={errors} getValues={getValues} />
          <ShippingAddress setValue={setValue} register={register} errors={errors} getValues={getValues} />
          <OrderProductsComponent setValue={setValue} register={register} errors={errors} getValues={getValues} control={control} watch={watch} />
          <OrderNotes setValue={setValue} register={register} errors={errors} getValues={getValues} />
        </div>
        <div className="col_5">
          <SelectShippingMethods setValue={setValue} errors={errors} />
          <CouponCode setValue={setValue} register={register} errors={errors} getValues={getValues} applycoupon={setCoupon}  />
          <OrderVendorCommission order={orderForm} />
          <OrderSummaryComponent setValue={setValue} register={register} errors={errors} getValues={getValues} control={control} watch={watch} coupon={coupon} />
          <OrderFormSubmitBtn isloading={isPending} />
        </div>
      </div>
    </form>
    </>
  );
}

export default FormComponent