import TextArea from "../../../../components/UI/form/TextArea";

const OrderNotes = ({errors,register, getValues, setValue}) => {
  return (
    <>
      <TextArea label="Buyer Note"
                name="buyer_note"
                errors={errors}
                placeholder={"Buyer Note"}
                register={register}/>

      <TextArea label="Vendor Note"
                name="vendor_note"
                errors={errors}
                placeholder={"Vendor Note"}
                register={register}/>
    </>
  );
}

export default OrderNotes