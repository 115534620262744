import Button from "../../../../../components/UI/Button";
import { useDispatch, useSelector } from 'react-redux';
import {setSelectToggle, setSendRequest} from "../../../../../store/slices/productSlice";
import {useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {productCombinedAction} from "../../../../../api/products";

const ProductModificationActionsComponent = ()=> {

  const [form, setForm] = useState({product_ids: [], action: '', category: ''})

  const store = useSelector(state => state.product);
  const dispatch = useDispatch()

  const selectToggle = (type)=> {
    dispatch(setSelectToggle({type: type}))
  }

  const [toastId, setToastId] = useState(null);
  const {mutate} = useMutation({
    mutationFn: ()=>productCombinedAction(form),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Action Successfull!", type: "success", isLoading: false, autoClose: true  });
      dispatch(setSendRequest(true))
      dispatch(setSelectToggle({type: 'unselect'}))
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const selectedItemActionConfirmation = (type)=>{
    if(store.selectedProducts.length){
      setForm(queryParam => ({...queryParam,action:type, product_ids: [...store.selectedProducts]}));
      mutate(form)
    }
  }

  const getCategoryChangeEvent = (event) => {
    setForm(queryParam => ({...queryParam, category:event.target.value}));
  }

  function SamplePrevArrow({subcat}) {
    return (subcat.sub_categories.map((item)=> (<option key={item.id} value={`${subcat.id},${item.id}`}>--{item.name}</option>)))
  }

  return (
    <div className="d_flex_btwn pt_10 pb_5">
      <div className="d_flex_inline">
        <Button text={"Select All"}
                classes={'mr_5'}
                onClickHandler={()=> selectToggle('select')}
                variant={"btn_primary"} />

        <Button text={"Unselect All"}
                classes={'mr_5'}
                onClickHandler={()=> selectToggle('unselect')}
                variant={"btn_primary"} />
      </div>
      <div className="d_flex_inline">
        {store.selectedProducts.length ?
          <>
            <Button text={store.activeTab ? 'Deactivate' : 'Activate'}
                    onClickHandler={() => selectedItemActionConfirmation(store.activeTab ? 'deactivate' : 'activate')}
                    classes={'mr_5'}
                    variant={"btn_info"}/>

            {!store.activeTab &&
            <Button text={"Delete"}
                    onClickHandler={() => selectedItemActionConfirmation('delete')}
                    variant={"btn_danger"} />}

          </>: ''
        }
      </div>
    </div>
  );
}

export default ProductModificationActionsComponent;