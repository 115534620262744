const ProductCommonErrorsComponent = ({errors, currentTab}) => {
  return(
    <div className={"product_form_common_errors"}>
      {((errors && errors.variants && errors.variants.length) && currentTab != 'Variations') && <span className={"text_red font_12p"}>Something wrong with Variants Tab.</span>}
      {((errors && errors.inventories && errors.inventories.length) && currentTab != 'inventory') && <span className={"text_red font_12p"}>Something wrong with Inventory Tab.</span>}
      {((errors && (errors.sku || errors.parent_category_id || errors.name || errors.price)) && currentTab != null) && <span className={"text_red font_12p"}>Something wrong with Product Information Tab.</span>}
      {((errors && errors.media && errors.media.length) && currentTab != 'Media') && <span className={"text_red font_12p"}>Something wrong with Media Tab.</span>}
    </div>
  )
}

export default ProductCommonErrorsComponent