import {configureStore} from "@reduxjs/toolkit";
import authSliceReducer from './slices/authSlice';
import productSliceReducer from "./slices/productSlice";
import orderSlice from "./slices/orderSlice";
export default configureStore({
  reducer: {
    auth: authSliceReducer,
    product: productSliceReducer,
    orders: orderSlice,
  }
})