import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import { updateVendorInformation} from "../../api/profile";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {useForm} from "react-hook-form";
import Modal from "../../components/UI/Modal";
import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";

const ProfileInfoUpdateComponent = ({openModal, setOpenModal, setUpdateData, column, vendor}) => {
  const [toastId, setToastId] = useState(null);
  const {isPending, mutate} = useMutation({
    mutationFn:  (data) => updateVendorInformation(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      setUpdateData(res)
      toast.update(toastId, { render: `${column} Updated`, type: "success", isLoading: false, autoClose: true });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const {setError, reset, handleSubmit, setValue, register, formState: { errors }} = useForm();

  const onSubmit = (data) => {
    mutate(data)
  }
  useEffect(()=>{
    if(vendor){
      setValue(column, vendor[column])
    }
  }, [vendor])

  return (
    <>
      <Modal title={`Update ${column}`} open={openModal} setOpen={setOpenModal} size={"modal_850p"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12">
              <Input label={column}
                     name={column}
                     errors={errors}
                     validationRules={  {required: true } }
                     register={register}/>
            </div>
            <div className="col_12">
              <div className="d_flex_end">
                <Button
                  text="update"
                  isPending={isPending}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ProfileInfoUpdateComponent