import Input from "../../components/UI/form/Input";
import Select from "../../components/UI/form/Select";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getCAstate, getCountries, getUSstate} from "../../api/commonRequest";
import RadioBox from "../../components/UI/form/RadioBox";
import TextArea from "../../components/UI/form/TextArea";
import {storeVendors, updateVendor} from "../../api/vendor";
import ReactiveButton from 'reactive-button';
import {toast} from "react-toastify";
import {useNavigate, useParams} from 'react-router-dom';
import SubHeadingComponent from "../../components/UI/SubHeadingComponent";
import {setValidationMessage} from "../../util/validationMessage";
import {useState} from "react";
import Button from "../../components/UI/Button";


const VendorFormComponent = ({watch, setError, handleSubmit, errors, register}) => {
  const navigate = useNavigate();
  const params = useParams();
  let currentState = [];
  const queryClient = useQueryClient();


  const {data: usStates } = useQuery({
    queryKey:['usstate'],
    queryFn: getUSstate
  })

  const {data: caStates} = useQuery({
    queryKey:['castate'],
    queryFn: getCAstate
  })

  const {data: countries} = useQuery({
    queryKey:['countries'],
    queryFn: getCountries
  })

  if (watch('setting.location') === 'US') {
    currentState = usStates
  } else if (watch('setting.location') === 'CA'){
    currentState = caStates
  } else  {
    currentState = []
  }

  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: params.id ? (data) => updateVendor(params.id, data) : storeVendors,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Vendor ${params.id ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'vendors',
      });

      navigate('/vendors');
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubHeadingComponent heading={`Vendor Information`} />

        <div className="row">
          <div className="col_6 pr_20">
            <Input label="First Name"
                   name="first_name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Last Name"
                   name="last_name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Email"
                   name="email"
                   type={'email'}
                   errors={errors}
                   validationRules={{required: true, type: 'email'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Password"
                   name="password"
                   type={'password'}
                   errors={errors}
                   validationRules={{required: params.id === undefined, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Company Name"
                   name="company_name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Business Name"
                   name="business_name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Web Site"
                   name="setting.website"
                   errors={errors}
                   validationRules={{ type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Commission Rate"
                   name="setting.rate"
                   errors={errors}
                   validationRules={{required: true, type: 'number'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <TextArea label="Company Information"
                      name="setting.store_info"
                      errors={errors}
                      validationRules={{ type: 'string'}}
                      register={register}/>
          </div>

        </div>

        <SubHeadingComponent heading={`Address`} />

        <div className="row">
          <div className="col_12 pr_20">
            <RadioBox label="Location"
                      name="setting.location"
                      errors={errors}
                      optionValue={'id'}
                      optionLabel={'name'}
                      options={[{id: 'US', name: 'United States'}, {id:"CA", name:"Canada"}, {id:"INT", name:"International"}]}
                      validationRules={{required:true, type: 'string'}}
                      register={register}/>

          </div>

          <div className="col_6 pr_20">
            <Input label="Store No."
                   name="setting.store_no"
                   errors={errors}
                   validationRules={{type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Address"
                   name="setting.address"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Attention"
                   name="setting.attention"
                   errors={errors}
                   validationRules={{type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Unit"
                   name="setting.unit"
                   errors={errors}
                   validationRules={{type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="City"
                   name="setting.city"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            {watch('setting.location') === 'US' || watch('setting.location') === 'CA'?
                <Select
                    label="State"
                    name="setting.state_id"
                    options={currentState && currentState.length ? [...currentState] : []}
                    errors={errors}
                    optionValue={'id'}
                    optionLabel={'name'}
                    validationRules={{required: true}}
                    register={register}/>

                :

                <Input label="State"
                       name="setting.state_text"
                       errors={errors}
                       validationRules={{required: true, type: 'string'}}
                       register={register}/>
            }
          </div>

          <div className="col_6 pr_20">
            <Input label="Zip"
                   name="setting.zip"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Select
                label="Country"
                name="setting.country_id"
                options={countries}
                errors={errors}
                optionValue={'id'}
                optionLabel={'name'}
                validationRules={{required: true}}
                register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Phone"
                   name="setting.phone"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Fax"
                   name="setting.fax"
                   errors={errors}
                   validationRules={{type: 'string'}}
                   register={register}/>
          </div>

        </div>

        <div className="col_12">
          <div className="d_flex_end">
            <Button
              isPending={isPending}
              text="SAVE"
              type="submit"
            />
          </div>
        </div>

      </form>
  );
};

export default VendorFormComponent;