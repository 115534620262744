import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";

const TextArea = ({ inline = false, label, name, placeholder = 'Description', register, validationRules = {}, errors =null,  classes = ''}) => {

    return (
        <div className={`${ classes } ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
            <label className={validationRules.required ? 'required' : ''}>{label}</label>
            <div className="form_input">
                <textarea {...register(name, validationRules)} className="form_global" rows="5" placeholder={placeholder}></textarea>
            </div>

            {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
        </div>
    );
};

export default TextArea;