import Input from "../../../../../components/UI/form/Input";

export const InventoryItem = ({control,index, errors = [], register, watch, setValue, getValues, addNewVariant, removeRow})=>{

return (
  <>
    <div className="row">
      <div className="col_3">
        {getValues(`inventories[${index}].variant_combination`) && getValues(`inventories[${index}].variant_combination`).map((field, i) => (
          <span key={i}> {field.value} {(getValues(`inventories[${index}].variant_combination`).length - 1) > i ? "/" : ''} </span>
        ))}
      </div>
      <div className="col_3">
        <Input
               name={`inventories[${index}].sku`}
               placeholder={`${getValues('sku')}`}
               errors={errors}
               register={register}/>
      </div>
      <div className="col_3">
        <Input
               name={`inventories[${index}].price`}
               errors={errors}
               placeholder={`${getValues('price')}`}
               register={register}/>
      </div>
      <div className="col_3">
        <Input
               name={`inventories[${index}].available_quantity`}
               errors={errors}
               register={register}/>
      </div>
    </div>
  </>
);
}

export default InventoryItem;