import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import Modal from "../../../components/UI/Modal";
import {updateCoupon} from "../../../api/coupon";

const CouponDetailsUpdateForm = ({editRecord = null, openModal, setOpenModal, updateConfirm})=>{
    const {setValue, reset, register, setError, handleSubmit, formState: { errors }} = useForm();

    useEffect(() => {
        reset();
        if (editRecord) {
            setValue('name', editRecord.name);
        }
    }, [openModal, editRecord]);


    const [toastId, setToastId] = useState(null);

    const {isPending, mutate} = useMutation({
        mutationFn: (data) => updateCoupon(editRecord.id, data),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, { render: `Coupon Updated`, type: "success", isLoading: false, autoClose: true });
            updateConfirm()
            setOpenModal(false)
            reset();
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }

            toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
        }
    });

    const onSubmit = (data) => {
        mutate(data)
    }
    return (
        <>
            <Modal title={`Update Coupon`} open={openModal} setOpen={setOpenModal}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col_12">
                            <Input label="Name"
                                   name="name"
                                   errors={errors}
                                   validationRules={{required: true, type: 'string'}}
                                   register={register}/>
                        </div>
                    </div>

                    <div className="col_12 mb_10">
                        <div className="d_flex_end">
                            <Button
                                text="save"
                                isPending={isPending}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default CouponDetailsUpdateForm