import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";

const RadioBox = ({ label,inline = false, classes= '', optionValue = 'id', optionLabel = 'name',  name, register, options= [], validationRules = {}, errors =null}) => {

    return (
        <div className={`${ classes } ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
            <label className={validationRules.required && 'required'}> {label}</label>
            <div className="d_inline_flex w_maxContent">
            {
                options.map((item)=>{
                    return(
                    <div className="form_input w_maxContent" key={item[optionValue]}>
                        <div className="form_radio">
                            <input type="radio"
                                   value={item[optionValue]}
                                   id={item[optionLabel]}
                                   {...register(name, validationRules)} />
                            <label className={'w_maxContent'} htmlFor={item[optionLabel]}>{item[optionLabel]}</label>
                        </div>
                    </div>
                    )
                })
            }

            </div>

            {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
        </div>
    );
};

export default RadioBox;