import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import {getOrders} from "../../api/orders";
import { useSelector} from "react-redux";
import OrderFilterComponent from "./includes/OrderFilterComponent";
import PreloaderComponent from "../../components/UI/PreloaderComponent";
import {useState} from "react";
import VendorOrderTableAction from "./includes/VendorOrderTableAction";
import OrderTableActionComponent from "./includes/OrderTableActionComponent";

const OrdersListComponent = ()=> {
  const store = useSelector(state => state.orders)
  const [isFetch, setIsFetch] = useState(false)

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: 'Date '
    }),
    columnHelper.accessor('order_number', {
      id: 'order_number',
      header: 'Order Number '
    }),
    columnHelper.accessor('customer.name', {
      id: 'customer.name',
      header: 'Customer Name'
    }),
    columnHelper.accessor('shipping_method.name', {
      id: 'shipping_method.name',
      header: 'Shipping Method'
    }),
    columnHelper.accessor('vendor_commission_rate', {
      id: 'vendor_commission_rate',
      header: 'Commission Rate',
      cell: props => <span> {props.row.original.vendor_commission_rate ? props.row.original.vendor_commission_rate : 0}% </span>
    }),
    columnHelper.accessor('vendor_commission_amount', {
      id: 'vendor_commission_amount',
      header: 'Commission Amount',
      cell: props => <span> ${props.row.original.vendor_commission_amount ? props.row.original.vendor_commission_amount.toFixed(2) : '0.00'} </span>
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: 'Total',
      cell: props => <span> ${props.row.original.total.toFixed(2)} </span>
    }),

    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <OrderTableActionComponent id={props.row.original.id} />
    }),
  ];

  return(
    <>
      <OrderFilterComponent />
      <Table
        queryKey={"orders"}
        columns={columns}
        setIsFetched={setIsFetch}
        queryParams={{...store.initialParams, type: store.activeTab}}
        queryFn={getOrders}
      ></Table>
      {isFetch && <PreloaderComponent />}
    </>
  );
}

export default OrdersListComponent;