import Card from "../../components/UI/Card";
import {getVendors} from "../../api/vendor";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import VendorListAction from "../../views/vendors/VendorListAction";
import VendorActiveComponent from "../../views/vendors/VendorActiveComponent";
import VendorVerifyComponent from "../../views/vendors/VendorVerifyComponent";

const Vendor = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      id: "name",
      header: "Company Name"
    }),
    columnHelper.accessor('business_name', {
      id: "business_name",
      header: "Business Name"
    }),
    columnHelper.accessor('email', {
      id: "email",
      header: "Email"
    }),
    columnHelper.display({
      id: "full_name",
      header: "Name",
      cell: props => props.row.original.first_name + ' ' + props.row.original.last_name,
    }),
    columnHelper.display({
      id: "rate",
      header: "Commission Rate",
      cell: props => props.row.original.setting ? (props.row.original.setting?.rate + '%') : '',
    }),
    columnHelper.display({
      id: 'active',
      header: "Active",
      cell: props => <VendorActiveComponent id={props.row.original.id}
                                            active={props.row.original.active} />
    }),
    columnHelper.display({
      id: 'verified',
      header: "Verified",
      cell: props => <VendorVerifyComponent id={props.row.original.id}
                                            active={props.row.original.verified} />
    }),
    columnHelper.accessor('created_at', {
      id: "created_at",
      header: "Created At"
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      classes: 'text_right',
      cell: props => <VendorListAction row={props.row.original} />,
    }),
  ];

  return(
    <>
      <Card title="Vendors">
        <Table queryKey={`vendors`}
               columns={columns}
               queryParams={{per_page: 10}}
               queryFn={getVendors} />
      </Card>
    </>
  )
}

export default Vendor;