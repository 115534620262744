import Card from "../../components/UI/Card";
import React, {useEffect, useState} from "react";
import {getVendorDetails} from "../../api/vendor";
import TabComponent from "../../components/UI/TabComponent";
import {getOrderTypes} from "../../api/orders";
import {useDispatch} from "react-redux";
import {setActiveTab, setOrderSendRequest, setOrderTypes, setVendorDetails} from "../../store/slices/orderSlice";
import OrdersListComponent from "../../views/orders/OrdersListComponent";
import {useLoaderData} from "react-router-dom";

export async function loader({params}){
  const [ orderType] = await Promise.all([
    getOrderTypes(params.id)
  ]);

  return { orderType};
}

const VendorOrderList = () =>{
  const {vendor, orderType} = useLoaderData();

  const dispatch = useDispatch()
  const [tabItems, setTabItems] = useState([
    {
      id: "all",
      title: "All Orders",
      component: <OrdersListComponent />
    }
  ])


  useEffect(()=>{
    if(orderType){
      let types = [];
      Object.keys(orderType).forEach(key => {
        types.push(orderType[key])
        setTabItems(tabItems => [...tabItems, {id:orderType[key].id, title: orderType[key].type,  component: <OrdersListComponent /> }])
      });
      dispatch(setOrderTypes(types))
    }
  }, [ ])

  const tabChangeEvent = (activeTab) => {
    dispatch(setActiveTab(activeTab))
  }

  return (
    <>
      <Card title={vendor? vendor.company_name + " Orders" : ''}>
        <TabComponent items={tabItems} tabChangeEvent={tabChangeEvent} />
      </Card>
    </>
  );
}

export default VendorOrderList