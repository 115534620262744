import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useLoaderData, useNavigate} from "react-router-dom";
import InformationComponent from "./form_includes/information/InformationComponent";
import VariantComponent from "./form_includes/variant/VariantComponent";
import MediaComponent from "./form_includes/media/MediaComponent";
import TabComponent from "../../../components/UI/TabComponent";
import InventoryComponent from "./form_includes/inventory/InventoryComponent";
import SubmitActionButtonGroup from "./form_includes/SubmitActionButtonGroup";
import {storeProduct, updateProduct} from "../../../api/products";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentEditProduct} from "../../../store/slices/productSlice";
import ProductCommonErrorsComponent from "./form_includes/ProductCommonErrorsComponent";

export const  ProductFormNew = ({watch, setError, control, handleSubmit, errors, register, setValue, getValues, clearErrors})=>{
  const product = useSelector(state => state.product.currentEditProduct)
  const [toastId, setToastId] = useState(null);
  const [currentTab, setCurrentTab] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {mutate, isPending, reset, } = useMutation({
    mutationFn: product ? (data) => updateProduct(product.id, data) : storeProduct,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Product  ${product ? 'Edited' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'products',
      });
      reset()
      navigate('/products');
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);

        if (error.message.message)
          errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    clearErrors()

    mutate(data)
  }

  const tabItems = [
    {
      id: 'product-info',
      title: 'Information',
      component:  <InformationComponent
        watch={watch}
        getValues={getValues}
        control={control}
        setValue={setValue}
        setError={setError}
        handleSubmit={handleSubmit}
        errors={errors}
        register={register}
        clearErrors={clearErrors}
      />
    },
    {
      id: 'Variations',
      title: 'Variations',
      component:  <VariantComponent  watch={watch}
                                     getValues={getValues}
                                     control={control}
                                     setValue={setValue}
                                     setError={setError}
                                     handleSubmit={handleSubmit}
                                     errors={errors}
                                     clearErrors={clearErrors}
                                     register={register} />
    },
    {
      id: 'inventory',
      title: 'Inventories',
      component:  <InventoryComponent  watch={watch}
                                       getValues={getValues}
                                       control={control}
                                       setValue={setValue}
                                       setError={setError}
                                       currentTab={currentTab}
                                       handleSubmit={handleSubmit}
                                       errors={errors}
                                       clearErrors={clearErrors}
                                       register={register} />
    },
    {
      id: 'Media',
      title: 'Media',
      component:  <MediaComponent watch={watch}
                                  errors={errors}
                                  clearErrors={clearErrors}
                                  register={register}
                                  setValue={setValue} />
    }
  ];

  return(
    <>
      <ProductCommonErrorsComponent errors={errors} currentTab={currentTab} />
      <form
            onSubmit={e => {
        clearErrors()
        handleSubmit(onSubmit)(e)
      }}>
        <TabComponent items={tabItems} tabChangeEvent={setCurrentTab}  />

        <SubmitActionButtonGroup isloading={false} />
      </form>
    </>
  );
}

export default ProductFormNew