import {getCustomerHistory} from "../../api/customers";
import {Link, useLoaderData} from "react-router-dom";
import Card from "../../components/UI/Card";


export async function customerDetails({params}){
  const [details] = await Promise.all([
    getCustomerHistory(params.id)
  ]);

  return {details};
}
const CustomerHistory = () => {
  const {details} = useLoaderData();
  const {customer, orders, status} = details

  return(
    <>

      {!status ?
        <Card>
          <span>
            Customer Not Found
          </span>
        </Card>
        :
        <>
        <Card title={`${customer ? customer.name : 'Customer'}'s personal information`}>
          <div className="table_responsive">
            <table className={'table'}>
              <thead>
                <tr>
                  <td className={'width_260p fw_bold'}>Name:</td>
                  <td>{customer.name}</td>
                </tr>
                <tr>
                  <td className={'width_260p fw_bold'}>Email:</td>
                  <td>{customer.email}</td>
                </tr>
                <tr>
                  <td className={'width_260p fw_bold'}>Phone:</td>
                  <td>{customer.phone}</td>
                </tr>
                <tr>
                  <td className={'width_260p fw_bold'}>Last Order:</td>
                  <td>{customer.last_order}</td>
                </tr>
                <tr>
                  <td className={'width_260p fw_bold'}>Total Order:</td>
                  <td>{customer.count}</td>
                </tr>
              </thead>
            </table>
          </div>
        </Card>

        <Card title={`${customer ? customer.name : 'Customer'}'s Orders`}>
          <div className="table_responsive">
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order Number</th>
                  <th>Shipping Method</th>
                  <th>Commission Rate</th>
                  <th>Commission Amount</th>
                  <th>Total</th>
                  <th className={'text_right'}>#</th>
                </tr>
              </thead>
              <tbody>
              {orders.map((item)=>
                <tr>
                  <td>{item.created_at}</td>
                  <td>{item.order_number}</td>
                  <td>{item.shipping_method ? item.shipping_method.name : null}</td>
                  <td>{item.vendor_commission_rate ? item.vendor_commission_rate : 0}%</td>
                  <td>${item.vendor_commission_amount ? item.vendor_commission_amount : '0.00'}</td>
                  <td>${item.total.toFixed(2)}</td>
                  <td className={'text_right'}>
                    <Link className={"btn btn_info"} to={"/orders/"+item.id}>Details</Link>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </Card>

        </>
      }

    </>
  );
}

export default CustomerHistory;