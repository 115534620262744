import {useFieldArray} from "react-hook-form";
import React, {useEffect, useState} from "react";
import Button from "../../../../components/UI/Button";
import {useDispatch, useSelector} from "react-redux";
import {removeOrderFormProduct, updateOrderItem} from "../../../../store/slices/orderSlice";
import {noImagePlaceholder} from "../../../../util/helper";

const OrderProductList = ({ setValue, register, errors, getValues, control, watch}) => {

  const { fields:productFields, append, remove} = useFieldArray({
    control,
    name: 'products',
  });
  const [productErrors, setProductErrors] = useState(false)
  const {products} = useSelector(state => state.orders.orderForm);
  const dispatch = useDispatch()
  const unstructureJson = (array) => {
    let string = ''

    if(typeof array === 'string'){
      if(typeof array === 'string')
        array= JSON.parse(array)
    }

    if(array && array.length) {
      array.map((item) => {
        string += `${item.name}:${item.value}`
      })
    }

    return string;
  }

  const newErrors = Object.keys(errors).map((key) =>  key);

  useEffect(()=>{
    setProductErrors(false)
  }, [products])

  useEffect(()=>{
     if(newErrors && newErrors.includes('products') && !products.length){
       setProductErrors(true)
     }
  }, [newErrors])


  const updateQty = (e,i) => {
    dispatch(updateOrderItem({quantity:e.target.value, index:i}))
  }

  return (
    <>
      <div className="view_order_details">
        {productErrors && <span className={'text_red'}>Please select Product</span>}
      <div className="img_wrap">
        {products && products.map((product, i)=>(
        <div className="inner p_relative" key={'product_'+i}>
          <div className="inner_wrap">
            <img src={product.url} width={100} alt="" onError={noImagePlaceholder} />
              <div className="text">
                <div className={"d_flex_inline"}>
                  <div className="form_row mb_0">
                    <div className="form_input">
                      <input type="number" className={"form_global height_25p"} value={product.quantity} onChange={(e)=>updateQty(e, i)} />
                    </div>
                  </div>
                </div>
                {product.sku && <h2 className={"mt_5"}>{product.sku}</h2>}
                <p>Unit Price:${product.price.toFixed(2)}</p>
                <p>Total price: ${(product.price * product.quantity) .toFixed(2)}</p>
                {product.product_variant_values && <p>{product.product_variant_values && unstructureJson(JSON.parse(product.product_variant_values))}</p> }
                <div className="delete_btn p_absolute t_0 mr_5 r_0">
                  <button type={"button"} className="btn btn_danger" onClick={()=>dispatch(removeOrderFormProduct(product))}><i className="fas fa-trash-alt"></i></button>
                </div>
              </div>
          </div>
        </div>))
        }
      </div>
      </div>
    </>
);
}

export default OrderProductList