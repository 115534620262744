import InventoryItem from "./InventoryItem";
import {useEffect} from "react";
import InventoryCombinationHeading from "./InventoryCombinationHeading";
import {useFieldArray} from "react-hook-form";

export const InventoryComponent = ({currentTab, watch, setError, control, setValue, handleSubmit, errors, register, getValues})=>{

  const { fields: varientFields, append: appendVariant, remove:removeVariant} = useFieldArray({
    control,
    name: 'inventories',
  });

  useEffect(()=>{
    if(currentTab === 'inventory'){
      generateInventoryCombinnation();
    }
  }, [currentTab])

  useEffect(()=>{
    generateInventoryCombinnation();
  }, [watch('variants')])


  function generateCombinations(arrays) {
    const result = [];
    function generate(current, index) {
      if (index === arrays.length) {
        result.push(current.slice());
        return;
      }

      const currentArray = arrays[index].values;
      if(currentArray.length) {
        for (const element of currentArray) {
          current.push({value: element.name, name: arrays[index].name});
          generate(current, index + 1);
          current.pop();
        }
      }
    }

    generate([], 0);
    return result;
  }

  const generateInventoryCombinnation = ()=>{

    if(getValues('variants').length) {

      let array = []
      getValues('variants').map(item=> item.values.length && array.push(item))
       if(array.length) {
         const combinations = generateCombinations(array);
         setValue(`inventories`, combinations.map((inventory, i) => ({
           variant_combination: inventory,
           sku: varientFields && varientFields[i]?.sku ? varientFields[i].sku : '',
           price: varientFields && varientFields[i]?.price ? varientFields[i].price : '',
           available_quantity: varientFields && varientFields[i]?.available_quantity ? varientFields[i].available_quantity : '',
         })))
       }
    } else {
      setValue(`inventories`, [])
    }
  }

  return (
    <>
      <div className="row mt_15 m_height_200p">
        <div className="col_12">
          <InventoryCombinationHeading />

          {varientFields && varientFields.map((field, index) => (
            <InventoryItem errors={errors}
                           key={`category_${index}`}
                           watch={watch}
                           setValue={setValue}
                           control={control}
                           register={register}
                           getValues={getValues}
                           index={index} />
          ))}
        </div>
      </div>
    </>
  );
}

export default InventoryComponent