import Button from "../../../../../components/UI/Button";

export const VariantCardActions = ({clickHandler})=>{
  return (
    <div title={"Remove Variant"} className={'p_relative'} style={{"marginTop":"-36px","marginRight":"-25px"}}>
      <Button
        text={<i className="lni lni-circle-minus"></i>}
        variant={"btn_danger"}
        classes={'p_absolute t_0 r_0 mr_10' }
        onClickHandler={clickHandler}
      />
    </div>
  );
}

export default VariantCardActions