import {Link} from "react-router-dom";
import Button from "../../../components/UI/Button";
import {confirmAlert} from "react-confirm-alert";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteShippingMethod} from "../../../api/shippingMethods";
import {toast} from "react-toastify";
import {deleteOrder} from "../../../api/orders";

const OrderTableActionComponent = ({id}) =>{
  const [toastId, setToastId] = useState(null);

  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: (data)=>deleteOrder(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Order Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'orders',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })
  const deleteHandler = () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }
  return (
    <>
      <Link  to={"/orders/"+id} className={"mr_5"}>
        <Button text={"view orders"}
                classes={'mr_5'}
                variant={"btn_info"} />
      </Link>
      <Button text={"Delete"} variant={'btn_danger'} onClickHandler={deleteHandler} />
    </>
  );
}

export default OrderTableActionComponent;