import Button from "../../../../../components/UI/Button";
import VariantItem from "./VariantItem";
import {useFieldArray} from "react-hook-form";

export const VariantComponent = ({watch, clearErrors, setError, control, setValue, handleSubmit, errors, register, getValues})=>{
  const { fields: varientFields, append: appendVariant, remove:removeVariant} = useFieldArray({
    control,
    name: 'variants',
  });

  return (<>
    <div className="row">
      <div className="col_12">
        <div className="d_flex_end" title={'Add New Variant'}>
          <Button
            text={<i className="lni lni-plus"></i>}
            onClickHandler={()=> appendVariant({name: '', values: []})}
            type={"button"}
          />
        </div>
      </div>
    </div>

    <div className="row mt_15 m_height_200p">
      <div className="col_12">
        {getValues("variants") && getValues("variants").map((field, index) => (
          <VariantItem errors={errors}
                       key={`category_${index}`}
                       watch={watch}
                       setValue={setValue}
                       control={control}
                       register={register}
                       getValues={getValues}
                       clearErrors={clearErrors}
                       removeRow={removeVariant}
                       addNewVariant={()=> appendVariant({name: "", values: []})}
                       index={index} />
        ))}
      </div>
    </div>

  </>)
}

export default VariantComponent