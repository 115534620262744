import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";

const Select = ({ label = "", optionValue = 'id', optionLabel = 'name', options = [], inline = false, name, register, validationRules = {}, errors =null}) => {


  return (
    <div className={`${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
      {label && <label>{label}</label>}
      <div className="form_input">
        <select className="form_global" {...register(name, validationRules)}>
          <option value="">Select Item</option>
          {options instanceof Array &&
            <>
              {options.map(option =>
                <option key={`option_item_${label}_${option.id}`}
                        value={option[optionValue]}>
                  {option[optionLabel]}
                </option>)
              }
            </>
          }
        </select>
      </div>

      {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
    </div>
  );
};

export default Select;