import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";
export const TextEditor = ({onchangeHandler = null, data= '', label, name, placeholder = 'Description', validationRules = {}, errors =null,  classes = ''})=>{


  return (
    <div className={`${ classes } ${ 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
      <label className={validationRules.required ? 'required' : ''}>{label}</label>
      <div className="form_input">
        <CKEditor
          editor={ ClassicEditor }
          data={data}
          onChange={ ( event, editor ) => {
            onchangeHandler(editor.getData())
          } }
        />
      </div>

      {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
    </div>

  );
}

export default TextEditor