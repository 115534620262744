import {Link} from "react-router-dom";
import Card from "../../../components/UI/Card";
import React from "react";

const CodeDetailsCouponInformation = ({code})=>{
    return (
        <Card title={`${code ? code.code : 'Code Details'}`}>
            <div className="row">
                <div className="col">
                    <div className="card mini-stats">
                        <div className="card_heading">
                              <span className="mr_5">
                                  <i className="font_16p fa fa-user"></i>
                              </span>
                            Summary
                        </div>
                        <div className="card_body">
                            <div className="mini-stats-content">
                                <div className="inner">
                                    <h2>Coupon</h2>
                                    <p>
                                        <Link  to={`/coupons/${code.coupon ? code.coupon.id : ''}`} className="text_red">
                                            {code.coupon ? code.coupon.name : ''}
                                        </Link>
                                    </p>
                                </div>
                                {code.coupon.vendor_id &&
                                <div className="inner" style={{"borderRight": "1px solid rgb(222, 222, 221)"}}>
                                    <h2>Usage</h2>
                                    <p>{code.times_redeemed} out of {code.max_redemptions} redemption</p>
                                </div>
                                }
                                <div className="inner">
                                    <h2>Expires on</h2>
                                    <p>{code.expires_at} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default CodeDetailsCouponInformation