import { confirmAlert } from 'react-confirm-alert';
import {Link} from "react-router-dom";
import ReactiveButton from "reactive-button";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {vendorDelete} from "../../api/vendor";

const VendorListAction = ({row}) => {
  const [toastId, setToastId] = useState(null);

  const queryClient = useQueryClient()

  const {mutate,isPending} = useMutation({
    mutationFn: vendorDelete,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Vendor Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'vendors',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const deleteHandler = () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: row.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          }
        }
      ]
    });

  };

  return (
    <>
      <Link  to={"/vendors/edit/"+row.id} className="mr_5">
        <ReactiveButton color={"yellow"} size="small" className={"btn"} idleText="Edit" />
      </Link>

      <ReactiveButton
          buttonState={isPending ?  'loading' : 'idle'}
          idleText="Delete"
          size="small"
          className={'btn btn_danger'}
          loadingText="Loading"
          successText="Done"
          onClick={deleteHandler}
      />

    </>
  );
};

export default VendorListAction;