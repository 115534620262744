import Card from "../../../components/UI/Card";
import {getTransactions} from "../../../api/commissionTransaction";
import {Link} from "react-router-dom";
import Table from "../../../components/UI/Table";
import {createColumnHelper} from "@tanstack/react-table";
import CommissionTransactionFilter from "../../../views/commission-transactions/CommissionTransactionFilter";
import {useState} from "react";

const CommissionTransactions = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "created_at",
      header: "Date",
      cell: props => <span> {props.row.original.created_at}</span>,
    }),
    columnHelper.display({
      id: "type",
      header: "Transaction Type",
      cell: props => <span className={`${props.row.original.type === 'commission' ? '' : 'text_red'} capitalized`} > {props.row.original.type}</span>,
    }),
    columnHelper.display({
      id: "order_number",
      header: "Order",
      cell: props => <Link className={"text_blue"} to={`/orders/${props.row.original.order_id}`}>{props.row.original.order_number ? '$'+props.row.original.order_number : ''}</Link>,
    }),
    columnHelper.display({
      id: "sub_total",
      header: "Sub Total",
      cell: props => <span>{props.row.original.sub_total ? '$'+ props.row.original.sub_total.toFixed(2) : ''}</span>,
    }),
    columnHelper.display({
      id: "commission_rate",
      header: "Commission Rate",
      cell: props => <span>{props.row.original.commission_rate ? props.row.original.commission_rate+'%' : ''} </span>,
    }),
    columnHelper.display({
      id: "amount",
      header: "Amount",
      cell: props => <span className={`${props.row.original.type === 'commission' ? '' : 'text_red'}`} > {props.row.original.type === 'withdraw' ? '-' : ''} ${props.row.original.amount.toFixed(2)}</span>,
    }),

  ];

  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
    search: '',
    start_date: '',
    end_date: '',
    vendor_id: '',
  })


  return (
    <>
      <Card title={`${'Transaction History'}`}>
        <CommissionTransactionFilter params={params} setParams={setParams} />
        <Table queryKey={`transactions`}
               columns={columns}
               queryParams={params}
               queryFn={getTransactions} />
      </Card>
    </>
  );
}

export default CommissionTransactions