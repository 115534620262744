export const activeRoute = (location) => {
    let keys = location.split('/')
    if(keys && keys.length > 0)
        keys = keys.filter(Boolean)

    return keys
}

export const findObjectById = (items, targetId, nestedArrayKey = '') => {
    for (const category of items) {
        if (category.id === targetId) {
            return category;
        }

        const foundObject = findObjectById(category[nestedArrayKey], targetId);
        if (foundObject) {
            return foundObject;
        }
    }

    return null;
}
export const noImagePlaceholder = (e) => {
    return e.target.src = "/images/no-image.png"
}