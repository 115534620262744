import styles from './SubHeadingComponent.module.css';

const SubHeadingComponent = ({heading}) => {
  return (
    <>
      <div className="row">
        <div className="col_12">
          <div className="card_heading pl_0 fw_bold">{heading}</div>
        </div>
      </div>
      <hr className={styles.hr_muted + " mb_10"} />
    </>
  );
};

export default SubHeadingComponent;