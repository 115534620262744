import Card from "../../../components/UI/Card";

const OrderVendorCommission = ({order}) => {
  return (
    <>
      {(order && order.vendor_commission_rate) && <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">Vendor Commission</h1>
       <div>
        <b>Commission Rate:</b> {order.vendor_commission_rate}% <br/>
        <b>Commission Amount:</b> ${order.vendor_commission_amount} <br/>
      </div>

    </Card>}
    </>
  );
}

export default OrderVendorCommission