import EmptyImage from "../../../../../components/UI/EmptyImage";
import {Link} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {selectUnselectProduct} from "../../../../../store/slices/productSlice";
import {noImagePlaceholder} from "../../../../../util/helper";

const ProductComponent = ({product})=> {
  const dispatch = useDispatch();

  const handleCheckboxChange = (event)=>{
    dispatch(selectUnselectProduct(product.id));
  }

  const store = useSelector(state => state.product);

  return (
    <>
      <div className="item_list_inner">
        <div className="item_list_inner_wrap">
          <div className="list_icon">
            {product.parent_category && <p className="date" title={product.parent_category.name}>{product.parent_category.name}</p> }
            <ul>
              <li><Link to={`/products/edit/${product.id}`}>
                <span><img src="/assets/media/images/edit.png" alt="" /></span>
              </Link>
              </li>
            </ul>
          </div>
          <div className="img">
            <div className="left_box">
                  <div className="form_checkbox">
                    <input type="checkbox" id={`item_list_${product.id}`} checked={store.selectedProducts.find(item => item === product.id) ? true : false} name="checkbox" onChange={(event)=>{handleCheckboxChange(event,'name')}}  />
                    <label htmlFor={`item_list_${product.id}`}></label>
                  </div>

            </div>
            {product.media && product.media.length > 0 ?
              <img src={product.media[0].url} alt="" onError={noImagePlaceholder} />
              :
              <EmptyImage />
            }
          </div>
          <div className="text">
            <p className="style_no mb_0">{ product.sku }</p>
            <p className="price">${ product.price.toFixed(2) }</p>
            <p className="date_normal color_grey">{ product.created_at }</p>
          </div>
        </div>
      </div>
    </>
);
}

export default ProductComponent;