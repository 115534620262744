import React from "react";

const PreloaderComponent = () => {
  return (
    <div id='preloader'>
      <div id='preloader_inner'>
        <div className="loader"></div>
      </div>
    </div>
  )
};

export default PreloaderComponent;