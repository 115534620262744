import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import Modal from "../../../components/UI/Modal";
import {storeCouponCode, updateCoupon} from "../../../api/coupon";
import RadioBox from "../../../components/UI/form/RadioBox";
import Checkbox from "../../../components/UI/form/Checkbox";

const AddCodeForm = ({coupon = null, openModal, setOpenModal, updateConfirm})=>{
    const {setValue, reset, register, getValues, setError,watch, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{
            code: '',
            active: "1",
            expires_at: '',
            max_redemptions: '',
            minimum_amount: '',
            first_time_transaction: false,
            free_shipping: false
        }
    });
    const [checkedStatus, setCheckedStatus] = useState({});
    const handleCheckbox = (e, column) =>{
        setCheckedStatus(prevStatus => ({
            ...prevStatus,
            [column]: e.target.checked,
        }));
    }

    useEffect(()=>{
        if(getValues('max_redemptions_limit')){
            setValue('max_redemptions', 1)
        } else {
            setValue('max_redemptions', '')
        }
    }, [watch('max_redemptions_limit')])

    useEffect(()=>{
        reset()
        setCheckedStatus({})
    }, [openModal])


    const [toastId, setToastId] = useState(null);

    const {isPending, mutate} = useMutation({
        mutationFn: (data) => storeCouponCode(data),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, { render: `Coupon has been added`, type: "success", isLoading: false, autoClose: true });
            updateConfirm()
            setOpenModal(false)
            reset();
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }

            toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
        }
    });

    const onSubmit = (data) => {
        data.coupon_id = coupon.id
        mutate(data)
    }
    return (
        <>
            <Modal title={`Add New Code`} open={openModal} setOpen={setOpenModal}>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="row" >
                        <div className="col_6 pr_20">
                            <RadioBox label="Status"
                                      name={`active`}
                                      errors={errors}
                                      optionValue={'id'}
                                      optionLabel={'name'}
                                      options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                                      validationRules={{required:true, type: 'string'}}
                                      register={register}/>
                        </div>
                        <div className="col_6 pr_20">
                            <Input label="Code"
                                   name={`code`}
                                   errors={errors}
                                   validationRules={{required: true, type: 'string'}}
                                   register={register}/>
                        </div>
                        <div className="col_12 pr_20">
                            <Checkbox name={`first_time_transaction`}
                                      label={"Eligible for first-time orders only"}
                                      classes="mb_0 pt_10"
                                      register={register}
                                      checked={getValues(`first_time_transaction`) ? 1 : 0} />
                        </div>
                        <div className="col_12 pr_20">
                            <Checkbox name={`free_shipping`}
                                      label={"Free shipping?"}
                                      classes="mb_0 pt_10"
                                      register={register}
                                      checked={getValues(`free_shipping`) ? 1 : 0} />
                        </div>
                        <div className="col_12 pr_20">
                            <Checkbox name={`max_redemptions_limit`}
                                      label={"Limit the number of times this code can be redeemed"}
                                      classes="mb_0 pt_10"
                                      register={register}
                                      onChangeHandler={(e)=>handleCheckbox(e, `max_redemptions_limit`)}
                                      checked={getValues(`max_redemptions_limit`) ? 1 : 0} />

                            {checkedStatus[`max_redemptions_limit`] && <div className={"pl_30"}>
                                <Input label="Redemptions times"
                                       name={`max_redemptions`}
                                       classes={"mt_5"}
                                       type={'number'}
                                       errors={errors}
                                       validationRules={{required: true, type: "number"}}
                                       register={register}/>
                            </div>}
                        </div>
                        <div className="col_12 pr_20">
                            <Checkbox name={`expires_at_date`}
                                      label={"Add an expiry date"}
                                      classes="mb_0 pt_10"
                                      register={register}
                                      onChangeHandler={(e)=>handleCheckbox(e, `expires_at_date`)}
                                      checked={getValues(`expires_at_date`) ? 1 : 0} />

                            {checkedStatus[`expires_at_date`] && <div className={"pl_30"}>
                                <Input label="Expires at"
                                       name={`expires_at`}
                                       classes={"mt_5"}
                                       type={'date'}
                                       errors={errors}
                                       validationRules={checkedStatus[`expires_at_date`] && {required: true}}
                                       register={register}/>

                            </div>}
                        </div>
                        <div className="col_12 pr_20">
                            <Checkbox name={`minimum_amount_limit`}
                                      label={"Require minimum order value"}
                                      classes="mb_0 pt_10"
                                      register={register}
                                      onChangeHandler={(e)=>handleCheckbox(e, `minimum_amount_limit`)}
                                      checked={getValues(`minimum_amount_limit`) ? 1 : 0} />

                            {checkedStatus[`minimum_amount_limit`] && <div className={"pl_30"}>
                                <Input label="Amount limit"
                                       name={`minimum_amount`}
                                       classes={"mt_5 "}
                                       type={'number'}
                                       errors={errors}
                                       validationRules={checkedStatus[`minimum_amount_limit`] && {required: true}}
                                       register={register}/>

                            </div>}
                        </div>
                        <div className="col_12 pr_20">
                            <div className="d_flex_end">
                                <Button
                                    isPending={isPending}
                                    text="SAVE"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddCodeForm