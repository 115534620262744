import {createBrowserRouter} from "react-router-dom";
import RootLayout, {loader} from "../views/layouts/RootLayout";
import ErrorPage from "../pages/ErrorPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/auth/LoginPage";
import Vendor from "../pages/vendor/VendorList";
import Customer from "../pages/customer/CustomerList";
import CustomerAddEdit from "../pages/customer/CustomerAddEdit";
import VendorAddEdit from "../pages/vendor/VendorAddEdit";
import {checkAuthLoader} from "../util/auth";
import ProductList from "../pages/products/ProductList";
import CategoryList from "../pages/products/settings/categories/CategoryList";
import VendorOrderList, {loader as vendorLoader} from "../pages/orders/VendorOrderList";
import AddEditProducts from "../pages/products/AddEditProducts";
import InvoiceList from "../pages/invoices/InvoiceList";
import OrderForm, {loaderOrderDetails} from "../pages/orders/OrderForm";
import ProfileSettings from "../pages/profile-settings/ProfileSettings";
import ShippingMethodPageComponent from "../pages/settings/shipping-methods/ShippingMethodPageComponent";
import CourierPageComponent from "../pages/settings/couriers/CourierPageComponent";
import UsersList from "../pages/users/UsersList";
import CommissionTransactions from "../pages/profile-settings/commission-transactions/CommissionTransactions";
import CustomerHistory, {customerDetails} from "../views/customers/CustomerHistory";
import UpdatePasswordForm from "../pages/update-password/UpdatePasswordForm";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import CouponForm from "../pages/coupons/CouponForm";
import CouponDetails, {loadCoupon} from "../pages/coupons/CouponDetails";
import CodeDetails, {loadCode} from "../pages/coupons/CodeDetails";
import CouponList from "../pages/coupons/CouponsList";
import React from "react";
import StylepickCouponList from "../pages/coupons/StylepickCouponList";



const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    loader: loader,
    children: [
      { index: true,
        element: <HomePage />
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: '/reset-password',
        element: <ResetPassword />
      },
      {
        path: '/new-password',
        element: <NewPassword />
      },
      {
        path: '/vendors',
        element: <Vendor />,
      },
      {
        path: '/vendors/add',
        element: <VendorAddEdit />,
      },
      {
        path: '/vendors/edit/:id',
        element: <VendorAddEdit />,
      },
      {
        path: '/customers',
        element: <Customer />,
      },
      {
        path: '/customers/add',
        element: <CustomerAddEdit />,
      },
      {
        path: '/customers/edit/:id',
        element: <CustomerAddEdit />,
      },
      {
        path: '/customers/details/:id',
        element: <CustomerHistory />,
        loader: customerDetails
      },
      {
        path: '/products',
        element: <ProductList />,
      },
      {
        path: '/products/add',
        element: <AddEditProducts />
      },
      {
        path: '/products/edit/:id',
        element: <AddEditProducts />
      },
      {
        path: '/categories',
        element: <CategoryList />,
      },
      {
        path: '/orders',
        element: <VendorOrderList />,
        loader: vendorLoader
      },
      {
        path: '/orders/create',
        element: <OrderForm />,
      },
      {
        path: '/invoices',
        element: <InvoiceList />,
      },
      {
        path: '/orders/:id',
        element: <OrderForm />,
        loader: loaderOrderDetails
      },
      {
        path: '/profile-settings',
        element: <ProfileSettings />
      },
      {
        path: '/change-password',
        element: <UpdatePasswordForm />
      },
      {
        path: '/settings',
        children: [
          {
            path: 'couriers',
            element: <CourierPageComponent />,
          },
          {
            path: 'shipping-methods',
            element: <ShippingMethodPageComponent />,
          },
        ]
      },
      {
        path: '/settings/users',
        element: <UsersList />
      },
      {
        path: '/transactions',
        element: <CommissionTransactions />
      },
      {
        path: '/coupons',
        element: <CouponList />,
      },
      {
        path: '/stylepick-coupons',
        element: <StylepickCouponList />,
      },
      {
        path: '/coupons/create',
        element: <CouponForm />,
      },
      {
        path: '/coupons/:id',
        element: <CouponDetails />,
        loader: loadCoupon
      },
      {
        path: '/coupons-code/:id',
        element: <CodeDetails />,
        loader: loadCode
      },
    ],
  },
]);

export default router;