import Input from "../../../../../components/UI/form/Input";
import Card from "../../../../../components/UI/Card";
import {useFieldArray} from "react-hook-form";
import Button from "../../../../../components/UI/Button";
import VariantCardActions from "./VariantCardActions";
import {useEffect, useState} from "react";
import {getErrorObj, getValidationMessage} from "../../../../../util/validationMessage";

export const VariantItem = ({control,index, errors = [], register, watch, setValue, getValues, addNewVariant, removeRow, clearErrors})=>{
  const { fields: valuesFields, append: appendValue, remove:removeValue} = useFieldArray({
    control,
    name: `variants[${index}].values`,
  });

  const [cardName, setCardName] = useState('')

  useEffect(()=>{
    setCardName(capitalize(getValues(`variants[${index}].name`)))
  }, [watch(`variants[${index}].name`)])

  function capitalize(str) {
    return str.toUpperCase()
  }

  const handleAddNewValueClick = () => {
    appendValue({name: ""})
    clearErrors(`variants[${index}].values`)
  }

  return (
    <>
      <Card title={ cardName ? cardName : `Variant [${index}]` } key={index}  CardActions={<VariantCardActions clickHandler={()=>removeRow(index)} />}>
        <div className="row mb_20">
          <div className="col_4">
            <Input
              name={`variants[${index}].name`}
              errors={errors}
              register={register}/>
          </div>
          <div className="col_8 pt_10">
            <div className="form_row has_error mb_0">
              {getErrorObj(errors, `variants.${index}.values`) && <small>At least One value required.</small>}
            </div>
            {getValues(`variants[${index}].values`) && getValues(`variants[${index}].values`).map((field, i) => (
              <div className="row pr_10" key={i}>
                <div className="col_12">
                  <Input label={`Values[${i+1}] `}
                         inline={true}
                         name={`variants[${index}].values[${i}].name`}
                         errors={errors}
                         register={register}/>
                  <div className="remove_varient_value p_absolute t_0 r_0" title={'Remove Value'}>
                    <Button
                      text={<i className="lni lni-circle-minus"></i>}
                      variant={'btn_danger height_40p'}
                      onClickHandler={()=> removeValue(i)}
                      type={"button"}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col_12">
              <div className="d_flex_end width_full" title={'Add Value'}>
                <Button
                  text={ <i className="lni lni-plus"></i>}
                  size={'btn_sm'}
                  onClickHandler={  handleAddNewValueClick}
                  type={"button"}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
        {getValues('variants').length === (index+1) && <div className="add_new_varient p_absolute b_0" style={{"marginBottom": '-10px'}} title={'Add New Variant'}>
          <Button
            text={<i className="lni lni-plus"></i>}
            onClickHandler={()=> addNewVariant()}
            type={"button"}
          />
        </div>}
      </Card>
    </>
  );
}

export default VariantItem