import API from "../util/api";

export const getCouriers = async (params) => {
  let data = null;

  await API.get('/couriers', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteCourier = async (params) => {
  let data = null;

  await API.delete('/couriers/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeCourier = async (payload) => {
  let data = null;

  await API.post('/couriers', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateCourier = async (id, payload) => {
  let data = null;

  await API.patch(`/couriers/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}