import {getProfileInfo} from "../../api/profile";
import {useMutation} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import Card from "../../components/UI/Card";
import EmptyImage from "../../components/UI/EmptyImage";
import LogoUploadComponent from "../../views/profile-settings/LogoUploadComponent";
import ProfileInfoUpdateComponent from "../../views/profile-settings/ProfileInfoUpdateComponent";

const ProfileSettings = () => {
  const [vendor, setVendor] = useState(null);
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(null);
  const [updateColumn, setColumn] = useState(null);

  const {mutate, isPending} = useMutation({
    mutationFn: () => getProfileInfo(),
    onSuccess: (data) => {
      setVendor(data)
    }
  })


  useEffect(()=>{
    if(!vendor){
      mutate();
    }
  }, [vendor])

  const handleModalOpenClick = (column) => {
    setColumn(column)
    setOpenInfoModal(true)
  }

  return (
    <>
      <Card title={`${vendor ? vendor.name : 'Vendor Profile'}`}>
        <div className="table-responsive">
          {vendor &&
          <table className={"table"}>
            <tbody>
            <tr>
              <td className={"width_200p"}><b>Name</b></td>
              <td>
                <div className="width_full p_relative">
                  {vendor.name}
                  <span onClick={()=>handleModalOpenClick('name')} className={'p_absolute r_0 t_0 cursor_pointer color_info btn_gradient_success p_5'}><i className="far fa-edit"></i></span>
                </div>
              </td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Email</b></td>
              <td>
                <div className="width_full p_relative">
                  {vendor.email}
                  <span onClick={()=>handleModalOpenClick('email')} className={'p_absolute r_0 t_0 cursor_pointer color_info btn_gradient_success p_5'}><i className="far fa-edit"></i></span>
                </div>
              </td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Business Name</b></td>
              <td>{vendor.legal_business_name}</td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Shop url</b></td>
              <td>
                <div className="width_full p_relative">
                /{vendor.slug}
                  <span onClick={()=>handleModalOpenClick('slug')} className={'p_absolute r_0 t_0 cursor_pointer color_info btn_gradient_success p_5'}><i className="far fa-edit"></i></span>
                </div>
              </td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Phone</b></td>
              <td>{vendor.phone}</td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Address</b></td>
              <td> {vendor.address}, {vendor.city} - {vendor.postal_code} </td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Logo</b></td>
              <td>
                <div className="width_full p_relative">
                  {vendor.logo ? <img src={vendor.logo} className={"width_150p"} alt=""/> :
                    <EmptyImage classes={"width_150p"}/>
                  }
                  <span onClick={()=>setOpenLogoModal(true)} className={'p_absolute r_0 t_0 cursor_pointer color_info btn_gradient_success p_5'}><i className="far fa-edit"></i></span>
                </div>
              </td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Commission Rate</b></td>
              <td> {vendor.commission}% </td>
            </tr>
            <tr>
              <td className={"width_200p"}><b>Commission Balance</b></td>
              <td className={"fw_bold"}> ${vendor.commission_balance ? vendor.commission_balance.toFixed(2) : "0.00"} </td>
            </tr>
            </tbody>
          </table>
          }
        </div>
      </Card>
      <LogoUploadComponent openModal={openLogoModal} setOpenModal={setOpenLogoModal} setUpdateData={setVendor} />
      {openInfoModal &&
      <ProfileInfoUpdateComponent openModal={openInfoModal} setOpenModal={setOpenInfoModal} setUpdateData={setVendor} column={updateColumn} vendor={vendor} /> }
    </>
  );
}

export default ProfileSettings;