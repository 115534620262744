import {RouterProvider} from "react-router-dom";
import router from "./router";
import {QueryClientProvider, QueryClient, MutationCache} from '@tanstack/react-query'
import {ToastContainer} from "react-toastify";

import './styles/scss/main.scss';
import './styles/scss/customStyle.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0
    }
  },
  mutationCache: new MutationCache({
    onError: (error, mutation) => {
      error.message = JSON.parse(error.message);
    },
  }),
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />

      <ToastContainer theme="dark" hideProgressBar={true} autoClose={1500} />
    </QueryClientProvider>
  );
}

export default App;
