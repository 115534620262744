import Card from "../../../components/UI/Card";
import React from "react";

const CodeDetailsComponent = ({code})=>{
    return (
        <Card title={"Details"}>
            <div className="row">
                <div className="col_6">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td className="width_260p">Created:</td>
                            <td className="width_260p">{code.created_at}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Expires on:</td>
                            <td className="width_260p">{code.expires_at}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Code:</td>
                            <td className="width_260p">{code.code}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Times Redeemed:</td>
                            <td className="width_260p">{code.times_redeemed}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Valid:</td>
                            <td className="width_260p">{code.active ? 'Yes' : 'No'}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div className="col_6">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td className="width_260p">Times of Redeemed:</td>
                            <td className="width_260p">{code.times_redeemed}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Free Shipping:</td>
                            <td className="width_260p">{code.free_shipping ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">First-time Order Only:</td>
                            <td className="width_260p">{code.first_time_transaction ? "yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Maximum Redemptions:</td>
                            <td className="width_260p">{code.max_redemptions}</td>
                        </tr>
                        {code.minimum_amount && <tr>
                            <td className="width_260p">Minimum Amount:</td>
                            <td className="width_260p">{code.minimum_amount}</td>
                        </tr>
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default CodeDetailsComponent