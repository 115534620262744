import {getCodeDetails} from "../../api/coupon";
import {useLoaderData} from "react-router";
import React, {useEffect, useState} from "react";
import CodeDetailsCouponInformation from "../../views/coupon/codes/CodeDetailsCouponInformation";
import CodeDetailsComponent from "../../views/coupon/codes/CodeDetailsComponent";

export async function loadCode({ params }) {
    const codeDetails = params.id ? await getCodeDetails(params.id) : undefined
    return {codeDetails}
}

const CodeDetails = ()=>{
    const {codeDetails} = useLoaderData()
    const [code, setCode ] = useState(null)

    useEffect(()=>{
        if(codeDetails){
            setCode(codeDetails)
        }
    }, [codeDetails])


    return (
        <>
            {code &&
            <>
                <CodeDetailsCouponInformation code={code} />

                <CodeDetailsComponent code={code} />
            </>}
        </>
    )
}

export default CodeDetails