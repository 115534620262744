import {useEffect, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getVendors} from "../../api/vendor";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
const CommissionTransactionFilter = ({params, setParams}) => {

  const queryparams = {
    per_page: 20,
    page: 1,
    search: '',
    start_date: '',
    end_date: '',
    vendor_id: '',
  }
  const [filterParams, setFilterParams] = useState({...queryparams});

  const handlePerPage = (e) => {
    setParams({...filterParams,  per_page: e.target.value, page:1});
    setFilterParams({...filterParams,  per_page: e.target.value, page:1});
  }

  const filterVendor = (event) => {
    setParams({...filterParams,  vendor_id: event.target.value, page:1});
    setFilterParams({...filterParams,  vendor_id: event.target.value, page:1});
  }

  const dateInputRef = useRef(null);
  const endDate = useRef(null);

  useEffect(() => {
    $(dateInputRef.current).datepicker({
      dateFormat: "yy-mm-dd",
      onSelect: function(dateText) {
        setParams({...filterParams,  start_date: dateText, page:1});
        setFilterParams({...filterParams,  start_date: dateText, page:1});
      }
    });

    $(endDate.current).datepicker({
      dateFormat: "yy-mm-dd",
      onSelect: function(dateText) {
        setParams({...filterParams,  end_date: dateText, page:1});
        setFilterParams({...filterParams,  end_date: dateText, page:1});
      }
    });
  }, []);


  return (
    <>
      <div className="color_filter_content mb_15">

        <div className="row">

          <div className="col_3">
            <div className="form_row ">
              <div className="form_input datepicker_global">
                <input type="text" placeholder={'Start Date'} className="datepicker form_global" ref={dateInputRef} />
              </div>
            </div>
          </div>

          <div className="col_3">
            <div className="form_row ">
              <div className="form_input datepicker_global">
                <input type="text" placeholder={'End Date'} className="datepicker form_global" ref={endDate} />
              </div>
            </div>
          </div>
          <div className="col_3"></div>
          <div className="col_3">
            <div className="d_flex_end">
              <select className="form_global max_width_200p" value={params.per_page} onChange={(event)=>{handlePerPage(event)}}>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="86">86</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
        </div>
    </>
  );
}

export default CommissionTransactionFilter