import { getCouponDetails} from "../../api/coupon";
import React, {useEffect, useState} from "react";
import {useLoaderData} from "react-router-dom";
import CouponDetailsCard from "../../views/coupon/coupon-details/CouponDetailsCard";
import CouponSummaryCard from "../../views/coupon/coupon-details/CouponSummaryCard";
import CouponPromoCodesCard from "../../views/coupon/coupon-details/CouponPromoCodesCard";
import CouponDetailsUpdateForm from "../../views/coupon/coupon-details/CouponDetailsUpdateForm";

export async function loadCoupon({ params }) {
    const couponDetails = params.id ? await getCouponDetails(params.id) : undefined
    return {couponDetails}
}

const CouponDetails = ()=>{
    const {couponDetails} = useLoaderData();
    const [openModal, setOpenModal] = useState(false)

    const[coupon, setCoupon] = useState(null)

    useEffect(()=>{
        if(couponDetails){
            setCoupon(couponDetails)
        }
    }, [couponDetails])

    const reloadCoupon = ()=>{
        loadCoupon({params:{id:coupon.id}}).then((res)=>{
            setCoupon(res.couponDetails)
        })
    }

    return (
        <>
            {coupon &&
            <>
                <CouponSummaryCard
                    coupon={coupon}
                />
                <CouponDetailsCard
                    coupon={coupon}
                    setModalStatus={setOpenModal}
                />
                <CouponPromoCodesCard
                    coupon={coupon}
                    deleteConfirm={reloadCoupon}
                />
            </>
            }
            <CouponDetailsUpdateForm editRecord={coupon}
                                     openModal={openModal}
                                     setOpenModal={setOpenModal}
                                     updateConfirm={reloadCoupon}
                                    />
        </>
    );
}

export default CouponDetails;