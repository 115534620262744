import { NavLink, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {activeRoute} from "../../util/helper";

const LeftNavComponent = () => {
  const [parentIndex, setParentIndex] = useState(null);
  const [subIndex, setSubIndex] = useState(null);

  const selectParentMenu = (index) => {
    setParentIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const selectSubMenu = (index) => {
    setSubIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const location = useLocation()
  useEffect(() => {
    let currentRoute = activeRoute(location.pathname)

    if(!currentRoute.length)
      setParentIndex(null)

    if(currentRoute.length)
      setParentIndex(currentRoute[0])

    if(currentRoute.length > 1)
      setSubIndex(currentRoute[1])

  }, [location]);

  const redirectHandler = (event, to) => {
    if (to === location.pathname) {
      event.preventDefault();
    }
  };

  return (
    <div id="left_nav">
      <ul className="left_nav_list">
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${!parentIndex ? 'active' : ''}`  }>
            <NavLink onClick={(event) => redirectHandler(event, '/')}
                     end to="/"> <span className={'left_img'}><i className="fas fa-tachometer-alt"></i></span> <span>Dashboard</span> </NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'products' ? 'active open' : ''}`  } onClick={() => selectParentMenu('products')}>
            <span className={'left_img'}><i className="fas fa-box-open"></i></span> <span>Products</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'products' ? ' current' : ''}`}>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/products')}
                       end to="/products">Product List</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/products/add')}
                       to="/products/add">Add New Product</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'categories'? 'active' : ''}`  }>
            <NavLink onClick={(event) => redirectHandler(event, '/')}
                     end to="/categories"> <span className={'left_img'}><i className="font_16p fas fa-network-wired"></i></span><span>Categories</span></NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'customers'? 'active' : ''}`  }>
            <NavLink onClick={(event) => redirectHandler(event, '/customers')}
                     end to="/customers"> <span className={'left_img'}><i className="font_16p fa fa-user"></i></span> <span>Customers</span></NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'orders' ? 'active open' : ''}`  } onClick={() => selectParentMenu('orders')}>
            <span className={'left_img'}><i className="fas fa-dollar-sign"></i></span> <span>Orders</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'orders' ? ' current' : ''}`}>
            <li><NavLink to="/orders">Order List</NavLink></li>
            <li><NavLink to="/orders/create">Create Order</NavLink></li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'settings' ? 'active open' : ''}`  } onClick={() => selectParentMenu('settings')}>
            <span className={'left_img'}><i className="fas fa-cogs"></i></span> <span>System Settings</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'settings' ? ' current' : ''}`}>
            <li><NavLink end to="/settings/shipping-methods">Shipping Methods</NavLink></li>
            <li><NavLink onClick={(event) => redirectHandler(event, '/settings/couriers')} end to="/settings/couriers">Couriers</NavLink></li>
            <li><NavLink end to="/settings/users">Users</NavLink></li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'coupons' ? 'active' : ''}`  }>
            <NavLink end to="/coupons"> <span className={'left_img'}><i className="fas fa-user-secret"></i> </span> <span>Coupons</span> </NavLink>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LeftNavComponent;