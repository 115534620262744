import Card from "../../../../components/UI/Card";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Button from "../../../../components/UI/Button";
import Input from "../../../../components/UI/form/Input";
import SelectAddressdList from "./SelectAddressdList";

const ShippingAddress = ({setValue, register, errors, getValues}) => {
  const {orderForm} = useSelector(state => state.orders)
  const [customer, setCustomer] = useState(null)
  const [openAddressModal, setAddressModal] = useState(false)
  const [editAddress, setEdit] = useState(false)
  const params = useParams()

  useEffect(()=>{
    if(customer === null) {
      setCustomer(orderForm.customer)
    }
  }, [orderForm])

  useEffect(()=>{
    if(customer) {
      if(customer.address.length) {
        if(!params.id) {
          selectedAddress(customer.address[0]);
        }
      }else {
        setEdit(true)
      }
    }
  }, [customer])

  const addressModalHandle = () => {
    setAddressModal(true)
  }

  const editBtnClickHandler = () => {
    setEdit(!editAddress)
  }

  const selectedAddress = (address) => {
    setValue('shipping_address_id', address.id)
    setValue('shipping_name', address.name)
    setValue('shipping_mobile', address.phone)
    setValue('shipping_address', address.address)
    setValue('shipping_city', address.city)
    setValue('shipping_postal_code', address.postal_code)
    setValue('shipping_country', address.country)
  }

  const billingFields = ['shipping_name', 'shipping_mobile', 'shipping_address', 'shipping_city', 'shipping_postal_code', 'shipping_country'];

  const newErrors = Object.keys(errors).map((key) =>  key);

  useEffect(()=>{
    if(newErrors.some(element => billingFields.includes(element))){
      setEdit(true)
    }
  }, [newErrors])

  return(
    <Card>
        <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
          <span> Shipping Address</span>
          <span className="fw_regular font_15p d_flex_center">
            {(customer && customer.address.length) ? (
              <>
          <img className="width_15p mr_10 cursor_pointer" onClick={editBtnClickHandler} src="/assets/media/images/edit.png" alt="" />
          <Button text={"change Address"} onClickHandler={addressModalHandle} />
              </>): ''
            }
        </span>
        </h1>
        {!editAddress &&
        <p className="mb_5">{getValues('shipping_name')} <br/>
          {getValues('shipping_mobile')}, <br/>
          <br/>{getValues('shipping_address')}, {getValues('shipping_city')}
          <br/> {getValues('shipping_postal_code')} </p>
        }
        <div className={`accordion_wrap ${editAddress ? 'd_block' : 'd_none'}`}>
          <div className="row">
            <div className="col_6">
              <Input label="Name"
                     inline={true}
                     name="shipping_name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_6">
              <Input label="Address"
                     inline={true}
                     name="shipping_address"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_6">
              <Input label="Mobile No."
                     inline={true}
                     name="shipping_mobile"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_6">
              <Input label="City"
                     inline={true}
                     name="shipping_city"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_6">
              <Input label="Post Code"
                     inline={true}
                     name="shipping_postal_code"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_6">
              <Input label="Country"
                     inline={true}
                     name="shipping_country"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            {(customer && customer.address.length) ? (
              <div className="col_6">
                <div className="width_full d_flex_end mt_20">
                  <Button text={"Ok"} onClickHandler={editBtnClickHandler} variant={'btn_primary'} />
                </div>
              </div>
            ) : ''}
          </div>
        </div>

      <SelectAddressdList openAddressModal={openAddressModal} setAddressModal={setAddressModal} setValue={setValue} selectedAddressType={'Shipping'} />
    </Card>
  )
}

export default ShippingAddress