import Card from "../../../../components/UI/Card";
import Button from "../../../../components/UI/Button";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {useDispatch} from "react-redux";
import {getCustomerDetails, searchCustomer} from "../../../../api/customers";
import {setOrderForm} from "../../../../store/slices/orderSlice";

const SearchCustomer = ()=>{
  const [customers, setCustomers] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState(false);

  const dispatch = useDispatch();
  const {mutate, isPending} = useMutation({
    mutationFn: (params) => searchCustomer(params),
    onSuccess: (data) => {
      setCustomers(data.data)
      if(data.data.length > 0){
        setStatus(false)
      } else {
        setStatus(true)
      }
    }
  })


  const enterPressHandler = (e) => {
    if(e.key === 'Enter'){
      searchHandler()
    }
  }

  const searchHandler = () => {
    if(searchKey) {
      mutate({search: searchKey})
    }
  }

  const selectUser = (customer) => {
    dispatch(setOrderForm({customer:customer, products:[], }))
  }

  return (
    <div className="search_customer_outer">
      <Card title={"Search Customer"}>
        <div className="row">
          <div className="col_12 pr_20">
            <div className="form_row">
              <div className="form_input">
                <input onChange={(e)=>setSearchKey(e.target.value)} onKeyPress={enterPressHandler} type="search" className="form_global" style={{"height": "40px"}} placeholder="Search by name, email, phone" />
                <Button onClickHandler={searchHandler} isPending={isPending} text={"SEARCH CUSTOMER"} classes={"width_200p"} size={'large'} />
              </div>
            </div>
          </div>
        </div>
      </Card>
      {customers.length ?
      <div className="customer_list_show">
        <div className="table_responsive">
          <table className={"table"}>
            <thead>
            <tr>
              <th><b>Name</b></th>
              <th><b>Email</b></th>
              <th><b>Phone</b></th>
              <th className={"text_right"}><b>#</b></th>
            </tr>
            </thead>
            <tbody>
            {customers.map((customer)=>(<tr key={customer.id}>
              <td>{customer.name}</td>
              <td>{customer.email}</td>
              <td>{customer.phone}</td>
              <td className={"text_right"}><Button onClickHandler={()=>selectUser(customer)} text={"Select"} /></td>
            </tr>))}
            </tbody>
          </table>
        </div>
      </div> :
         <>
           {(searchKey && status) &&
           <span className={"width_full text_center"}>No Result Found.</span>
           }
        </>
      }
    </div>
  );
}

export default SearchCustomer