export const getValidationMessage = (error, label) => {
  switch (error.type) {
    case 'required':
      return label + " is required";
    default:
      return error.message;
  }
};

export const setValidationMessage = (errors, setError) => {
  Object.keys(errors).forEach(function(key) {
    setError(key, {
      message: errors[key][0],
    });
  });
};

export const getErrorObj = (errors, n) => {
  n = n.replaceAll("[", ".");
  n = n.replaceAll("]", "");

  if (!n || !errors) return false;
  const parts = n.split(".");
  const key = parts.shift();
  if (!parts.length) return errors[key];
  return getErrorObj(errors[key], parts.join("."));
}