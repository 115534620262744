import {createSlice} from "@reduxjs/toolkit";
import {deleteOrderProduct} from "../../api/orders";

export const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orderTypes: [],
    sendRequest: false,
    vendorDetails: null,
    activeTab: 'all',
    initialParams:{
      page: 1,
      vendor_id: null,
      order_number: '',
      company_name: '',
      name: '',
      sort: 'created_at',
      sort_order: 'desc',
      per_page: 20,
      period: '',
      type: '',
      indicator_status: ''
    },
    orderForm:{
      customer: null,
      products: [],
    }
  },
  reducers: {
    setOrderTypes: (state, action) => {
      state.orderTypes = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
      state.initialParams = {
        ...state.initialParams,
        period:action.payload
      }
    },
    setOrderParams: (state, action) => {
      state.initialParams = action.payload
    },
    setOrderSendRequest: (state, action) => {
      state.sendRequest = action.payload
    },
    setVendorDetails: (state, action) => {
      state.vendorDetails = action.payload
    },
    setOrderForm: (state, action) => {
      state.orderForm = action.payload
    },

    addOrderFormProduct: (state, action) => {
      state.orderForm.products.push(action.payload);
    },

    updateOrderItem: (state, action) => {
      const { quantity, index:productIndex } = action.payload;
      if (productIndex !== -1) {
        const updatedProducts = state.orderForm.products.map((product, index) => {
          if (index === productIndex) {
            return { ...product, quantity: +quantity > 1 ? +quantity : 1 };
          }
          return product;
        });
        return {
          ...state,
          orderForm: {
            ...state.orderForm,
            products: updatedProducts,
          },
        };
      }
    },
    removeOrderFormProduct: (state, action) => {
      state.orderForm.products = state.orderForm.products.filter(product => product.product_id !== action.payload.product_id);
      if(action.payload.id && action.payload.id != ''){
        deleteOrderProduct(action.payload.id).then(r =>{

        } )
      }
    },
    updateOrderFormProduct: (state, data) => {
      const payload = data.payload
      const productIndex = state.orderForm.products.findIndex(product => product.product_id === payload.product_id);
      if (productIndex !== -1) {

        state.orderForm.products[productIndex] = payload;
      }
    },
  }

});
export const {
  setOrderTypes,
  setActiveTab,
  setOrderParams,
  setOrderSendRequest,
  setVendorDetails,
  setOrderForm,
  addOrderFormProduct,
  updateOrderItem,
  removeOrderFormProduct,
  updateOrderFormProduct
} = orderSlice.actions;
export default orderSlice.reducer;
