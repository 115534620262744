import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {keepPreviousData, useQuery, useQueryClient} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import Pagination from "./Pagination";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";


const Table = ({queryKey, columns, queryFn, queryParams = {}, staleTime = 5000, setIsFetched= false}) => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [params, setParsms] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  const {  data, isFetching, isPlaceholderData, isFetched, isPending } = useQuery({
    queryKey: [queryKey, page, params],
    queryFn: () => queryFn({...params}),
    placeholderData: keepPreviousData,
    staleTime,
  });

  useEffect(() => {
    if(setIsFetched) {
      setIsFetched(isFetching);
    }
  }, [isPending, isFetching]);
  useEffect(() => {
    // const urlparams = {};
    // for (const [key, value] of searchParams) {
    //   urlparams[key] = value ? value : null;
    // }
    // const hasValue = Object.values(urlparams).some(value => value !== undefined && value !== null);
    // if(searchParams.size > 0 && hasValue){
    //   setParsms(urlparams);
    //   console.log(1)
    // } else {
    //   console.log(2)
    setParsms(queryParams);
    // }
  }, [queryParams]);

  // Prefetch the next page!
  useEffect(() => {
    if (!isPlaceholderData && data?.meta && page < data.meta.last_page) {
      queryClient.prefetchQuery({
        queryKey: [queryKey, page + 1, params],
        queryFn: () => queryFn({...params, page: page + 1}),
        // staleTime
      })
    }
  }, [data, isPlaceholderData, page, queryClient]);


  function generateRoute(){
    navigate(`${location.pathname}?${encodeObjectToQueryString()}`);
  }

  const table = useReactTable({
    data: data ? data['data'] : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const encodeObjectToQueryString = (page = null) => {
    return Object.keys(params).map(key => key + '=' + (params[key] ? params[key] : '')).join('&');
  }

  const changePageHandler = (page) => {
    setPage(page);
    setParsms({...params, page});
  };

  return (
    <>
      <div className="table_responsive">
        <table className="table">
          <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className={'fw_600 '+ header.column.columnDef.classes || 'fw_600'}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody>
          {isFetching ?
            <tr>
              <td className="text_center" colSpan={table.getHeaderGroups()[0].headers.length}>
                <FontAwesomeIcon icon={faSpinner} spin/>
              </td>
            </tr> : (isFetched && !table.getRowModel().rows.length) ?
              <tr>
                <td className="text_center text_muted" colSpan={table.getHeaderGroups()[0].headers.length}>
                  No data found.
                </td>
              </tr> :
              <>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className={cell.column.columnDef.classes || ''}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
          }
          </tbody>
        </table>
      </div>

      {data?.meta && <Pagination meta={data.meta}
                                 isPlaceholderData={isPlaceholderData}
                                 changePageFn={changePageHandler} />}
    </>
  );
};

export default Table;