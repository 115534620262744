import {dashboardData} from "../api/dashboard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useQuery} from "@tanstack/react-query";
import PreloaderComponent from "../components/UI/PreloaderComponent";

const HomePage = () => {

  const {data: dashboard, isPending} = useQuery({
    queryKey:['dashboard'],
    queryFn: () => dashboardData()
  })



  setTimeout(()=>{
    window.$(".round").each(function() {
      var round = window.$(this);
      var roundRadius = round.find('circle').attr('r');
      var roundPercent = round.data('percent');
      var roundCircum = 2 * roundRadius * Math.PI;
      var roundDraw = roundPercent * roundCircum / 100;
      round.css('stroke-dasharray', roundDraw  + ' 999');
    });
  },500)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'  ,
      },
      title: {
        display: true,
        text: 'Summary Statistic',
      },
    },
  };
  const labels = ['Published Product', 'Total Sale', 'Order'];
  let data = null;
  if(dashboard) {
    data = {
      labels,
      datasets: [
        {
          label: 'Today',
          data: [dashboard.today_new_customer, dashboard.today_new_vendor, dashboard.today_new_product_published, dashboard.today_order_amount, dashboard.today_order_count, dashboard.today_visitors],
          borderColor: 'rgb(0, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        {
          label: 'Yesterday',
          data: [dashboard.yesterday_product_published, dashboard.yesterday_order_amount, dashboard.yesterday_order_count],
          borderColor: 'rgb(65, 65, 65)',
          backgroundColor: 'rgba(65, 65, 65, 0.5)',
        },
        {
          label: '7 days ago ',
          data: [dashboard.seven_days_product_published, dashboard.seven_days_order_amount, dashboard.seven_days_order_count],
          borderColor: 'rgb(125, 125, 125)',
          backgroundColor: 'rgba(125, 125, 125, 0.5)',
        },
        {
          label: '30 days ago ',
          data: [dashboard.thirty_days_product_published, dashboard.thirty_days_order_amount, dashboard.thirty_days_order_count],
          borderColor: 'rgb(207, 209, 208)',
          backgroundColor: 'rgba(207, 209, 208, 0.5)',
        },
      ],
    };
  }




  return (
    <>
      {isPending && <PreloaderComponent /> }
      {dashboard && <>
      <div className="row">
        <div className="col">
          <div className="card mini-stats">
            <div className="card_heading">
              <span className="mr_5"><i className="font_16p fas fa-dollar-sign"></i>
              </span> Order
            </div>
            <div className="card_body">
              <div className="mini-stats-content">
                <div className="inner">
                  <h2>${dashboard.total_sale.toFixed(2)}</h2>
                  <p>Sale</p>
                </div>
                <div className="inner">
                  <h2>{dashboard.total_order_count}</h2>
                  <p>Count</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card mini-stats">
            <div className="card_heading">
              <span className="mr_5"><i className="fas fa-cart-arrow-down"></i>
              </span> Cart
            </div>
            <div className="card_body">
              <div className="mini-stats-content">
                <div className="inner">
                  <h2>{dashboard.total_carts}</h2>
                  <p>Total</p>
                </div>
                <div className="inner">
                  <h2>{dashboard.today_carts}</h2>
                  <p>Today</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card mini-stats">
            <div className="card_heading">
              <span className="mr_5"><i className="font_16p fas fa-box-open"></i>
              </span> Product
            </div>
            <div className="card_body">
              <div className="mini-stats-content">
                <div className="inner">
                  <h2>{dashboard.total_active_products_active}</h2>
                  <p>Active</p>
                </div>
                <div className="inner">
                  <h2>{dashboard.total_active_products_inactive}</h2>
                  <p>InActive</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div className="row">
        <div className="col_3">
        <div className="card mini-stats">
        <div className="card_heading">
        <span className="mr_5">
        <i className="fas fa-calendar-week"></i>
        </span>
        Today's Summary
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.today_new_product_published}</h2>
        <p>Product Published</p>
        </div>
        <div className="inner">
        <h2>${dashboard.today_order_amount ? dashboard.today_order_amount.toFixed(2) : '0.00'}</h2>
        <p>Total Sale</p>
        </div>
        </div>
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.today_order_count}</h2>
        <p>Order Count</p>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className="col_3">
        <div className="card mini-stats">
        <div className="card_heading">
        <span className="mr_5">
        <i className="fas fa-calendar-week"></i>
        </span>
        Yesterday Summary
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.yesterday_product_published}</h2>
        <p>Product Published</p>
        </div>
        <div className="inner">
        <h2>${dashboard.yesterday_order_amount ? dashboard.yesterday_order_amount.toFixed(2) : '0.00'}</h2>
        <p>Total Sale</p>
        </div>
        </div>
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.yesterday_order_count}</h2>
        <p>Order Count</p>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className="col_3">
        <div className="card mini-stats">
        <div className="card_heading">
        <span className="mr_5">
        <i className="fas fa-calendar-week"></i>
        </span>
        Statistic From 7 Days Ago
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.seven_days_product_published}</h2>
        <p>Product Published</p>
        </div>
        <div className="inner">
        <h2>${dashboard.seven_days_order_amount ? dashboard.seven_days_order_amount.toFixed(2) : '0.00'}</h2>
        <p>Total Sale</p>
        </div>
        </div>
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.seven_days_order_count}</h2>
        <p>Order Count</p>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className="col_3">
        <div className="card mini-stats">
        <div className="card_heading">
        <span className="mr_5">
        <i className="fas fa-calendar-week"></i>
        </span>
        Statistic From 30 Days Ago
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.thirty_days_product_published}</h2>
        <p>Product Published</p>
        </div>
        <div className="inner">
        <h2>${dashboard.thirty_days_order_amount ? dashboard.thirty_days_order_amount.toFixed(2) : '0.00'}</h2>
        <p>Total Sale</p>
        </div>
        </div>
        </div>
        <div className="card_body">
        <div className="mini-stats-content">
        <div className="inner">
        <h2>{dashboard.thirty_days_order_count}</h2>
        <p>Order Count</p>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className="row">
        <div className="col_12 dashboard_chart">
        <Line
        options={options}
        data={data}
        />
        </div>
        </div>
      </>
      }
      <br/>
      <br/>
      <br/>
    </>
  );
};

export default HomePage;