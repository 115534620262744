import Modal from "../../../../components/UI/Modal";
import Button from "../../../../components/UI/Button";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const SelectAddressdList = ({selectedAddressType, openAddressModal, setAddressModal, setValue}) => {
  const {orderForm} = useSelector(state => state.orders)
  const [customer, setCustomer] = useState(null)
  useEffect(()=>{
    if(customer === null) {
      setCustomer(orderForm.customer)
    }
  }, [orderForm])

  const selectedAddress = (address) => {
    if(selectedAddressType === 'Shipping'){
      setValue('shipping_address_id', address.id)
      setValue('shipping_name', address.name)
      setValue('shipping_address', address.address)
      setValue('shipping_city', address.city)
      setValue('shipping_postal_code', address.postal_code)
      setValue('shipping_country', address.country)
    }
    if(selectedAddressType === 'Billing'){
      setValue('billing_address_id', address.id)
      setValue('billing_name', address.name)
      setValue('billing_address', address.address)
      setValue('billing_city', address.city)
      setValue('billing_postal_code', address.postal_code)
      setValue('billing_country', address.country)
    }
    setAddressModal(false)
  }
  return (
    <Modal title={`Select ${selectedAddressType} Address`} open={openAddressModal} setOpen={setAddressModal}>
      <div className="table_responsive">
        <table className={"table"}>
          <thead>
          <tr>
            <th><b>#</b></th>
            <th><b>Name</b></th>
            <th><b>Address</b></th>
            <th className={"text_right width_100p"}><b>#</b></th>
          </tr>
          </thead>
          <tbody>
          {customer && customer.address && customer.address.map((address, i)=>(<tr key={address.id}>
            <td>{i+1}</td>
            <td>{address.name}</td>
            <td>{address.address}, {address.city}-{address.postal_code}</td>
            <td className={"text_right width_100p"}><Button onClickHandler={()=>selectedAddress(address, selectedAddressType)}  text={"Select"} /></td>
          </tr>))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

export default SelectAddressdList