const Card = ({title = null, CardActions = null, children}) => {
  return (
    <div className="card">
      {title && <div className="card_heading">
        <div className={`${CardActions && "d_flex_btwn" }`}>
          { title }
          {CardActions}
        </div>
      </div>}
      <div className="card_body">
        <div className="row">
          <div className="col_12">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;